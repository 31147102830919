import {
  Box,
  Input,
  Text,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

import JobSearchCard from "../../Components/jobSearchCard/JobSearchCard";
import clipBoard from "../../../Assets/Images/icos/clipboard-text.png";
import message from "../../../Assets/Images/icos/messages.png";
import card from "../../../Assets/Images/icos/card-tick.png";
import profile from "../../../Assets/Images/icos/frame.png";
import clipboardTick from "../../../Assets/Images/icos/clipboard-tick.png";
import coin from "../../../Assets/Images/icos/coin.png";
import { ReactComponent as Searchicon } from "../../../Assets/Icons/search-normal.svg";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import Sidebar from "./sidebar/Sidebar";
import { GrClose } from "react-icons/gr";
import { useEffect, useState } from "react";
import getJobs from "../../../Api/Jobs/getJobsApi";
import { ColorRing } from "react-loader-spinner";
import { ReactComponent as SearchIcon } from "../../../Assets/Icons/search-normal.svg";

const Jobs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [budgetStart, setBudgetStart] = useState("");
  const [budgetEnd, setBudgetEnd] = useState("");
  const [budgetApply, setBudgetApply] = useState(false);
  const [deadline, setDeadline] = useState([]);
  const [genre, setGenre] = useState([]);
  const [search_color, set_search_color] = useState("rgba(43, 43, 43, .3)");
  const [search, set_search] = useState("");
  const [performance, setPerformance] = useState([]);

  // get jobs list
  const getData = async () => {
    try {
      const res = await getJobs();
      setLoading(false);
      setJobs(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  useEffect(() => {}, [genre]);

  useEffect(() => {
    document.title = "Udukku | Jobs";
  });

  const filterIt = () => {
    let temp = jobs;

    // FOR SEARCH
    if (search !== "") {
      temp = temp.filter((val) => {
        if (val.jobTitle.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        return false;
      });
    }

    // FOR CATEGORY
    if (category.length !== 0) {
      // means category is selected
      temp = temp.filter((val) => {
        if (category.indexOf(val.category.service) !== -1) {
          return true;
        }
        return false;
      });
    }

    // FOR BUDGET
    if (budgetApply) {
      temp = temp.filter((val) => {
        if (val.budget[0] >= budgetStart && val.budget[0] <= budgetEnd) {
          return true;
        }
        return false;
      });
    }

    // For Deadline
    if (deadline.length !== 0) {
      temp = temp.filter((val) => {
        if (deadline.indexOf(val.deadLine) !== -1) {
          return true;
        }
        return false;
      });
    }

    // For Genre
    if (genre.length !== 0) {
      temp = temp.filter((val) => {
        if (genre.indexOf(val.genres[0].genere) !== -1) {
          return true;
        }
        return false;
      });
    }

    // for performance type
    if (performance.length !== 0) {
      temp = temp.filter((val) => {
        if (performance.indexOf("Production") !== -1) {
          if (performance.indexOf("LiveShow") !== -1) {
            return true;
          }
        }

        if (performance.indexOf("Production") !== -1) {
          if (performance.indexOf("LiveShow") === -1) {
            if (val.liveShow) {
              return false;
            } else {
              return true;
            }
          }
        }

        if (performance.indexOf("Production") === -1) {
          if (performance.indexOf("LiveShow") !== -1) {
            if (val.liveShow) {
              return true;
            } else {
              return false;
            }
          }
        }

        if (performance.indexOf("Production") === -1) {
          if (performance.indexOf("LiveShow") === -1) {
            return true;
          }
        }
      });
    }

    return temp;
  };

  return (
    <>
      <Box pt="8.5vh">
        <NavBar />
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Jobs Filter</ModalHeader>
            <div
              style={{
                width: "100vw",
                height: "100vh",
                margin: 0,
                overflow: "auto",
                paddingBottom: "50px",
              }}
            >
              <Box
                display={{ base: "flex" }}
                flexDir={"column"}
                gap="20px"
                mt="2.31vh"
                alignItems={"center"}
                px="50px"
              >
                <Sidebar
                  setCategory={setCategory}
                  budgetStart={budgetStart}
                  budgetEnd={budgetEnd}
                  setBudgetStart={setBudgetStart}
                  setBudgetEnd={setBudgetEnd}
                  setDeadline={setDeadline}
                  setGenre={setGenre}
                  setBudgetApply={setBudgetApply}
                  setPerformance={setPerformance}
                />
                <div style={{ flexGrow: 1 }}></div>
                <Button
                  w="90%"
                  h="50px"
                  fontSize={"1.3rem"}
                  margin={"auto"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Close
                </Button>
              </Box>
            </div>
          </ModalContent>
        </Modal>
        <Box px={{ base: "7vw", lg: "13.54vw" }}>
          <Box
            display={{ md: "flex", sm: "block" }}
            mt={{ lg: "70px", base: "40px" }}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Text
              fontSize={{ base: "2.4rem", lg: "2.29vw" }}
              fontFamily={"Gilroy-Bold"}
            >
              Find your next projects
            </Text>

            <Box
              display="flex"
              w={{ md: "50%", sm: "100%" }}
              gap={{ base: ".5rem", lg: "" }}
            >
              <InputGroup
                width={{ sm: "100vw" }}
                w={{ base: "80%", lg: "100%" }}
                _focus={{
                  svg: { stroke: "rgba(246, 84, 14, 1) !important" },
                }}
              >
                <InputLeftElement
                  pointerEvents="none"
                  h="100%"
                  w="40px"
                  // p="8px"
                  children={<SearchIcon style={{ stroke: search_color }} />}
                  px={"5px"}
                  mr={"10px"}
                />
                <Input
                  borderRadius={"15px"}
                  h={{ base: "4rem", lg: "5rem" }}
                  pl={"40px"}
                  type="text"
                  fontSize={{ base: "1.2rem", lg: ".93vw" }}
                  placeholder="Enter talent name, category or genre"
                  _focus={{
                    border: "2px solid rgba(246, 84, 14, 1)",
                  }}
                  onFocus={() => {
                    set_search_color("rgba(246, 84, 14, 1)");
                  }}
                  onBlur={() => {
                    set_search_color("rgba(43, 43, 43, .3)");
                  }}
                  value={search}
                  onChange={(e) => {
                    set_search(e.target.value);
                  }}
                />
              </InputGroup>
              <Box
                display={{ base: "flex", lg: "none" }}
                borderRadius={"15px"}
                border="1.5px solid #F0F0F0"
                px="2rem"
                alignItems={"center"}
                justifyContent="center"
                onClick={() => {
                  onOpen();
                }}
              >
                <Text fontSize={"1.2rem"}>Filters</Text>
              </Box>
            </Box>
          </Box>
          <Box
            h="fit-content"
            w="100%"
            display={"flex"}
            flexWrap={"nowrap"}
            mt="2.777vh"
            pb="9.25vh"
          >
            {/* filters */}
            <Box className="job-sidebar">
              <Sidebar
                setCategory={setCategory}
                budgetStart={budgetStart}
                budgetEnd={budgetEnd}
                setBudgetStart={setBudgetStart}
                setBudgetEnd={setBudgetEnd}
                setDeadline={setDeadline}
                setGenre={setGenre}
                setBudgetApply={setBudgetApply}
                setPerformance={setPerformance}
              />
            </Box>

            {/* result card */}

            <Box
              className="jobCard"
              w="100%"
              h="fit-content"
              display={{ md: "flex", sm: "block" }}
              flexDir="column"
              gap="1.85vh"
            >
              {loading ? (
                <Box
                  w="100%"
                  h="27.77vh"
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={[
                      "#F6540E",
                      "#F6540E",
                      "#F6540E",
                      "#F6540E",
                      "#F6540E",
                    ]}
                  />
                </Box>
              ) : (
                <>
                  {/* {jobs
										.filter((data) => {
											if (search === "") {
												return true;
											}
											if (
												data.jobTitle
													?.toLowerCase()
													.includes(search.toLowerCase())
											) {
												return true;
											} else {
												return false;
											}
										})
										.filter((data) => {
											if (
												category.length === 0 &&
												deadline.length === 0 &&
												genre.length === 0 &&
												budgetStart === "" &&
												budgetEnd === ""
											) {
												return true;
											}

											

											if (
												checkForCategory(data.category) ||
												checkForDeadline(data.deadLine) ||
												checkForGenre(data.genres) ||
												checkForBudget(data.budget[0])
											) {
												return true;
											}

											return false;
										}) */}

                  {filterIt().length !== 0 ? (
                    filterIt().map((data, index) => {
                      return <JobSearchCard key={index} data={data} />;
                    })
                  ) : (
                    <Box
                      width={"100%"}
                      textAlign={"center"}
                      height={"300px"}
                      display={"flex"}
                      flexDir={"column"}
                      gap="10px"
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontSize={"18px"}
                      color="rgba(0,0,0,.3)"
                    >
                      <Text>No Jobs Found</Text>
                      <Text
                        fontSize={"14px"}
                        color="#F6540E"
                        textDecor={"underline"}
                        cursor={"pointer"}
                        onClick={() => {
                          set_search("");
                          setCategory([]);
                          setBudgetApply(false);
                          setBudgetEnd("");
                          setBudgetStart("");
                          setDeadline("");
                          setGenre([]);
                          setPerformance([]);
                        }}
                      >
                        Clear Filter
                      </Text>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Jobs;
