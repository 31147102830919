import { Box, Input, Select, Text } from "@chakra-ui/react";
import { ReactComponent as DeleteIcon } from "../../../../Assets/Icons/Delete.svg";
import { CategoryData } from "../../../../Data/CategoryData";
import styles from "../../../../Styles/talenRegistrationProfessional.module.css";

const Category = ({ showDelete, currentIndex, fullState, changeState }) => {
  const handleDelete = () => {
    changeState((prev) => {
      prev.splice(currentIndex, 1);
      return [...prev];
    });
  };

  const updating_categories = (e) => {
    // setCategory(e.target.value);
    changeState((prev) => {
      prev[currentIndex].category = e.target.value;
      return [...prev];
    });
  };

  const updating_sub_categories = (e) => {
    changeState((prev) => {
      prev[currentIndex].subCategory = e.target.value;
      return [...prev];
    });
  };

  const updating_staring_price = (e) => {
    changeState((prev) => {
      prev[currentIndex].serviceStargingPrice = e.target.value;
      return [...prev];
    });
  };

  return (
    <Box
      display={{ base: "grid", lg: "flex" }}
      gridTemplateColumns={"1fr 1fr .8fr"}
      gap={".833vw"}
      mt="1.111vh"
      position={"relative"}
      w="100%"
      alignItems={"end"}
      paddingRight={{ base: showDelete ? "10px" : "0" }}
    >
      <Box>
        <Text
          fontFamily={"Gilroy-SemiBold"}
          fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
        >
          Category*
        </Text>
        <Select
          borderRadius={"15px"}
          type={"text"}
          value={fullState[currentIndex].category}
          onChange={updating_categories}
          h="6.48vh"
          w="100%"
          display={"relative"}
        >
          <option value=""></option>
          {Object.keys(CategoryData).map((item) => (
            <option value={item}>{item}</option>
          ))}
        </Select>
      </Box>
      <Box>
        <Text
          fontFamily={"Gilroy-SemiBold"}
          fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
        >
          Subcategory*
        </Text>

        <Select
          borderRadius={"15px"}
          type={"text"}
          value={fullState[currentIndex].subCategory}
          onChange={updating_sub_categories}
          h="6.48vh"
          w="100%"
        >
          <option value=""></option>
          {!!fullState[currentIndex].category &&
            CategoryData[fullState[currentIndex].category].map((item) => (
              <option value={item}>{item}</option>
            ))}
        </Select>
      </Box>
      <Box>
        <Text
          fontFamily={"Gilroy-SemiBold"}
          fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
        >
          Service Starting Price
        </Text>
        <Input
          borderRadius={"15px"}
          type={"number"}
          value={fullState[currentIndex].serviceStargingPrice}
          onChange={updating_staring_price}
          h="6.48vh"
          w="100%"
        />
      </Box>
      {/* delete icon */}
      {showDelete ? (
        <DeleteIcon className={styles.icon} onClick={handleDelete} />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Category;
