import { Box, Image, Text } from "@chakra-ui/react";
import left from "./left.svg";
import right from "./right.svg";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useEffect, useRef, useState } from "react";
import getUserSlideDataApi from "../../../../Api/User/getUserSlideDataApi";
import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai";
import getAllTalents from "../../../../Api/AllTalents/allTalents";

const Slide = () => {
  const [index, setIndex] = useState(0);
  const [slides, set_slides] = useState([]);
  const prev = useRef();
  const next = useRef();

  // get the data for slide
  const getSlideData = async () => {
    try {
      const res = await getAllTalents();
      let temp = res.data.talents.filter(
        (val) => val.isProfileCompleted && val.isMusician === "Musician"
      );
      temp = temp.slice(0, 5);
      set_slides([...temp]);
    } catch (error) {}
  };

  useEffect(() => {
    getSlideData();

    const unsub = setInterval(() => {
      document.querySelector(".splide__arrow--next").click();
    }, 5000);

    return () => {
      clearInterval(unsub);
    };
  }, []);

  return (
    <Box
      flexShrink={0}
      w={{ base: "95vw", lg: "27.60vw" }}
      h={"100%"}
      borderRadius={"1.6vw"}
      overflow="hidden"
      position={"relative"}
      mx={{ base: "3.5rem" }}
      my={{ base: "3.5rem" }}
      id="profile_slides"
    >
      <Splide
        aria-label="images"
        hasTrack={false}
        options={{
          type: "loop",
          gap: "1rem",
          width: { base: "95vw", lg: "27.60vw" },
          height: "51.85vh",
          transition: "slide",
          interval: 3000,
          position: "relative",
          pagination: false,
        }}
        onMove={(e) => {
          setIndex(e.index);
        }}
      >
        <SplideTrack>
          {slides?.map((data, index) => {
            return (
              <SplideSlide key={index} style={{ position: "relative" }}>
                <Box
                  className="hero-image-radius"
                  w={{
                    base: "95vw",
                    lg: "27.60vw",
                  }}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Image
                    src={data.avatar}
                    width="100%"
                    height={"100%"}
                    objectFit="cover"
                    borderRadius="1.5rem"
                  />
                </Box>
              </SplideSlide>
            );
          })}
        </SplideTrack>

        <Box
          // display={'none'}
          position={"absolute"}
          h="fit-content"
          w={{ base: "20rem", lg: "fit-content" }}
          bg="rgba(8, 32, 50,.65)"
          bottom={0}
          right={0}
          borderRadius="32px 0 0 0"
          zIndex={"10"}
          padding={"20px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            position={"relative"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"flex-end"}
            minWidth={"200px"}
          >
            <Box
              h="4px"
              w={{ base: "10vw", lg: "1.66vw" }}
              bg="#F6540E"
              float={"right"}
              borderRadius="20px"
            ></Box>
            <Text
              pt="10px"
              color="#fff"
              fontSize={{ base: "2rem", lg: "1.1vw" }}
              textAlign="end"
              fontFamily="Gilroy-Medium"
            >
              {slides?.length === 0 ? "" : slides[index].name}
            </Text>
            <Text
              mt=".74vh"
              color="#fff"
              fontSize={{ base: "1rem", lg: ".8vw" }}
              fontFamily={"Gilroy-Bold"}
              textAlign="end"
              whiteSpace={"nowrap"}
            >
              {slides.length === 0 ? "" : slides[index].services[0]?.category}
            </Text>
            <Box
              display={"flex"}
              justifyContent="flex-end"
              position="relative"
              gap={{ base: "10vw", lg: "2vw" }}
              mt="2vh"
              marginInline={"auto"}
            >
              <Box
                h={{ base: "3rem", lg: "2.5vw" }}
                w={{ base: "3rem", lg: "2.5vw" }}
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                borderRadius="full"
                border="2px solid white"
                cursor={"pointer"}
                onClick={() => {
                  document.querySelector(".splide__arrow--prev").click();
                }}
              >
                <Image
                  src={left}
                  alt="left"
                  w={{ base: ".7rem", lg: ".5vw" }}
                />
              </Box>
              <Box
                h={{ base: "3rem", lg: "2.5vw" }}
                w={{ base: "3rem", lg: "2.5vw" }}
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                borderRadius="full"
                border="2px solid white"
                cursor={"pointer"}
                onClick={() => {
                  document.querySelector(".splide__arrow--next").click();
                }}
              >
                <Image
                  src={right}
                  alt="left"
                  w={{ base: ".7rem", lg: ".5vw" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Splide>
    </Box>
  );
};

export default Slide;
