var axios = require("axios");
const updateUserApi = async (
	id,
	avatar,
	name,
	mobile,
	city,
	state,
	description,
	services,
	genres,
	username,
	gearHighLights,
	socialMedia,
	work,
	terms,
	startingPrice
) => {
	const token = localStorage.getItem("token");

	var data = {
		isProfileCompleted: true,
		avatar,
		name,
		mobile,
		city,
		state,
		description,
		services,
		genres: genres,
		gearHighLights,
		socialMedia,
		terms,
		userName: username,
		startingPrice,
	};

	var config = {
		method: "patch",
		url: `${process.env.REACT_APP_BASE_URL}/user/update-user-by-id/${id}`,
		headers: {
			Authorization: token,
		},
		data: data,
	};

	return await axios(config);
};

export default updateUserApi;
