import {
  Box,
  Button,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import logo from "../../../Assets/Images/Logo/logo.svg";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import jsonp from "jsonp";
import { useCallback, useState } from "react";
import styles from "./footer.module.css";
import { useSelector } from "react-redux";
import { ReactComponent as Sms } from "../../../Assets/Icons/sms.svg";

const action_url =
  "https://udukku.us14.list-manage.com/subscribe/post-json?u=e18ebd0a4d8f02856ff89be0a&amp;id=28239d7933&amp;f_id=008799e0f0";

const Footer = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { user } = useSelector((state) => ({ ...state }));

  const onSubmitHandler = () => {
    setLoading(true);
    jsonp(`${action_url}&EMAIL=${email}`, { param: "c" }, (err, data) => {
      setLoading(false);

      if (data.result === "success") {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }

      if (err) {
        toast({
          title: "Error",
          description: "Something went wrong. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    });
  };

  return (
    <>
      <Box
        h={{ base: "fit-content" }}
        w="100vw"
        overflow={"hidden"}
        className="hide-scroll-bar"
        bg="#082032"
        display={"grid"}
        gridTemplateColumns={{ base: "1fr", lg: ".7fr 1.3fr .7fr" }}
        gap={{ base: "20px", lg: "0" }}
        py="6.77vh "
        color="#fff"
        px={{ base: "7vw", lg: "13.54vw" }}
      >
        <Box display={"flex"} flexDir="column">
          <Box>
            <Image
              src={logo}
              h={{ base: "80px", lg: "fit-content" }}
              width={"150px"}
            />
          </Box>
          <Box
            display={{ base: "block", lg: "block" }}
            alignItems={{ base: "center" }}
            gap={{ base: "2.5rem", lg: "0" }}
          >
            <Box w={{ base: "100%", lg: "100%" }}>
              <Text
                mt="1vh"
                mb="1.388vh"
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
              >
                A one-of-its-kind destination for musicians to harness their
                love of music and connect with each other.
              </Text>
            </Box>
            <Box pt={{ base: "0rem", lg: "0rem" }}>
              <Box
                display={"flex"}
                alignItems="flex-start"
                gap={{ base: "1rem", lg: ".26vw" }}
                mt={{ base: "0", lg: ".925vh" }}
              >
                <Icon
                  as={HiLocationMarker}
                  fontSize={{ base: "1.3rem", lg: ".833vw" }}
                  height={"1.3rem"}
                  width={"1.3rem"}
                />
                <Box position="relative" bottom="5px">
                  <Text
                    fontFamily={"Gilroy-Medium"}
                    fontSize={{ base: "12px", lg: ".833vw" }}
                  >
                    M-23 Income Tax Colony, Tonk Road, Durgapura, Jaipur -
                    302018
                  </Text>
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItems="flex-start"
                gap={{ base: "1rem", lg: ".26vw" }}
                mt=".925vh"
              >
                <Sms height={"1.3rem"} width={"1.3rem"} />
                <Text
                  fontFamily={"Gilroy-Medium"}
                  fontSize={{ base: "12px", lg: ".833vw" }}
                  as="a"
                  href="mailto:info@udukku.com"
                >
                  info@udukku.com
                </Text>
              </Box>

              <Box flexGrow={1}></Box>
              <Text
                fontFamily={"Gilroy-Medium"}
                mt=".925vh"
                fontSize={{ base: "12px", lg: ".833vw" }}
                display={{ base: "none" }}
              >
                ©udukku 2022
              </Text>
            </Box>
            <Box
              display={{ base: "flex", lg: "none" }}
              mt="2.77vh"
              gap={{ base: "2.8vw", lg: "1.66vw" }}
              justifyContent={"flex-start"}
            >
              <Box
                as="a"
                href="https://www.facebook.com/udukkumusic/"
                target="_blank"
                w={{ base: "2.4rem", lg: "2vw" }}
                h={{ base: "2.4rem", lg: "2vw" }}
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                className="hide-scroll-bar facebook"
                border="1px solid rgba(255,255,255,.2)"
                borderRadius={"5px"}
              >
                <Icon
                  as={FaFacebookF}
                  color="rgb(255,255,255)"
                  fontSize={{ base: "1rem", lg: ".833vw" }}
                />
              </Box>
              <Box
                as="a"
                w={{ base: "2.4rem", lg: "2vw" }}
                h={{ base: "2.4rem", lg: "2vw" }}
                href="https://www.instagram.com/udukkumusic/"
                target="_blank"
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                className="hide-scroll-bar instagram"
                border="1px solid rgba(255,255,255,.2)"
                borderRadius={"5px"}
              >
                <Icon
                  as={FaInstagram}
                  color="rgb(255,255,255)"
                  fontSize={{ base: "1rem", lg: ".833vw" }}
                />
              </Box>

              <Box
                as="a"
                href="https://twitter.com/udukkumusic"
                target="_blank"
                w={{ base: "2.4rem", lg: "2vw" }}
                h={{ base: "2.4rem", lg: "2vw" }}
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                className="hide-scroll-bar twitter"
                border="1px solid rgba(255,255,255,.2)"
                borderRadius={"5px"}
              >
                <Icon
                  as={FaTwitter}
                  color="rgb(255,255,255)"
                  fontSize={{ base: "1rem", lg: ".833vw" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDir={{ base: "column", md: "row", lg: "row" }}
          justifyContent="space-around"
          gap={{ base: "15px" }}
        >
          <Box display={user?.isMusician === "Musician" ? "none" : ""}>
            <Text
              fontSize={{ base: "16px", lg: "1.45vw" }}
              fontFamily={"Gilroy-Bold"}
            >
              Categories
            </Text>
            <Box
              display={"flex"}
              flexDir="column"
              mt="8px"
              lineHeight={"3.70vh"}
            >
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "category",
                      value: "VOCALISTS",
                    },
                  });
                }}
                cursor={"pointer"}
              >
                Vocalists
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "category",
                      value: "MUSIC PRODUCERS  & ENGINEERS",
                    },
                  });
                }}
                cursor={"pointer"}
              >
                Music Producer & Engineers
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "genre",
                      value: "National/Indian",
                    },
                  });
                }}
                cursor={"pointer"}
              >
                Indian Instruments
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: { type: "genre", value: "International" },
                  });
                }}
                cursor={"pointer"}
              >
                International Instruments
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "category",
                      value: "SONGWRITERS & COMPOSERS ",
                    },
                  });
                }}
                cursor={"pointer"}
              >
                Songwriters & Composers
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                onClick={() => {
                  navigate("/talents", {
                    state: {
                      type: "category",
                      value: "DJs",
                    },
                  });
                }}
                cursor={"pointer"}
              >
                DJs
              </Text>
            </Box>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "16px", lg: "1.45vw" }}
              fontFamily={"Gilroy-Bold"}
            >
              Support
            </Text>
            <Box
              display={"flex"}
              flexDir="column"
              lineHeight={"3.70vh"}
              mt="8px"
            >
              {/* <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "1.7rem", lg: ".833vw" }}
              >
                FAQs
              </Text> */}
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </Text>

              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/cancellation-and-refund");
                }}
              >
                Cancellation & Refund Policy
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
              >
                Terms & Conditions
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About Us
              </Text>
              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "12px", lg: ".833vw" }}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Contact Us
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
        >
          <Text
            textAlign={{ lg: "center" }}
            fontSize={{ base: "16px", lg: "1.45vw" }}
            fontFamily={"Gilroy-Bold"}
            mb="10px"
          >
            Subscribe to hear news
          </Text>
          <Box
            background="white"
            display={"flex"}
            alignItems={"center"}
            width={{ base: "100%", lg: "300px" }}
            borderRadius={"1rem"}
            paddingRight={"5px"}
          >
            <InputGroup
              w={{ base: "100%", lg: "21.51vw" }}
              h={{ base: "6.0vh", lg: "6vh" }}
              size={"lg"}
              display={"flex"}
              borderColor={"#fff"}
            >
              <Input
                className={styles.input_}
                bg="white"
                h="100%"
                pr="50px"
                type="email"
                placeholder="Email Address"
                borderRadius={{ base: "1rem", lg: "1rem" }}
                fontSize={{ base: "1rem", lg: ".92vw" }}
                color="#000"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                focusBorderColor="none"
              />
              <InputRightElement
                width="6.19vw"
                h="100%"
                borderRadius={{ base: "1rem", lg: "1rem" }}
                fontSize={{ base: "1rem", lg: ".92vw" }}
              ></InputRightElement>
            </InputGroup>
            <Button
              h={{ base: "5vh", lg: "5vh" }}
              w="100px"
              bg="rgba(246, 84, 14, 1)"
              borderRadius={"1rem"}
              _hover={{
                background: "#0E87F6",
              }}
              fontSize={{ base: "12px", lg: ".833vw" }}
              onClick={onSubmitHandler}
              isLoading={loading}
            >
              Send
            </Button>
          </Box>
          <Box
            display={{ base: "none", lg: "flex" }}
            mt="2.77vh"
            gap={{ base: "2.8vw", lg: "1.66vw" }}
            justifyContent={"center"}
          >
            <Box
              as="a"
              href="https://www.facebook.com/udukkumusic/"
              target="_blank"
              w={{ base: "2.4rem", lg: "2vw" }}
              h={{ base: "2.4rem", lg: "2vw" }}
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              className="hide-scroll-bar facebook"
              border="1px solid rgba(255,255,255,.2)"
              borderRadius={"5px"}
            >
              <Icon
                as={FaFacebookF}
                color="rgb(255,255,255)"
                fontSize={{ base: "2rem", lg: ".833vw" }}
              />
            </Box>
            <Box
              as="a"
              w={{ base: "2.4rem", lg: "2vw" }}
              h={{ base: "2.4rem", lg: "2vw" }}
              href="https://www.instagram.com/udukkumusic/"
              target="_blank"
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              className="hide-scroll-bar instagram"
              border="1px solid rgba(255,255,255,.2)"
              borderRadius={"5px"}
            >
              <Icon
                as={FaInstagram}
                color="rgb(255,255,255)"
                fontSize={{ base: "2rem", lg: ".833vw" }}
              />
            </Box>

            <Box
              as="a"
              href="https://twitter.com/udukkumusic"
              target="_blank"
              w={{ base: "2.4rem", lg: "2vw" }}
              h={{ base: "2.4rem", lg: "2vw" }}
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              className="hide-scroll-bar twitter"
              border="1px solid rgba(255,255,255,.2)"
              borderRadius={"5px"}
            >
              <Icon
                as={FaTwitter}
                color="rgb(255,255,255)"
                fontSize={{ base: "2rem", lg: ".833vw" }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          display={{ base: "block", md: "none", lg: "none" }}
          fontFamily={"Gilroy-semibold"}
          fontSize={"1rem"}
        >
          <Text>@udukku 2022</Text>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
