import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const not_allowed = ["/profile", "/dashboard"];

const AuthWrapper = ({ children }) => {
	const location = useLocation();
	const { user } = useSelector((state) => ({ ...state }));

	useEffect(() => {
		if (not_allowed.indexOf(location.pathname) !== -1) {
			// redirect user to home page
		} else {
			// don't do anything
		}
	}, [location.pathname]);

	return <>{children}</>;
};

export default AuthWrapper;
