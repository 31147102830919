import React from "react";
import styles from "../../../Styles/newMessages.module.css";
import NavBar from "../../Components/NavBar/NavBar";

class NewMessages extends React.Component {
  render() {
    return (
      <div className={styles.page_container}>
        <NavBar />
        <div className={styles.message_container}>
          <div className={styles.message_box}>
            <div className={styles.user_list}>
              <div className={styles.user_list_heading_part}>
                <h1>Messages</h1>
                <div className={styles.user_search_container}></div>
                <div className={styles.user_tabs}></div>
              </div>

              <div className={styles.users}></div>
            </div>
            <div className={styles.chat_box}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewMessages;
