import { Box, Button, Input, Select, Text, Textarea } from "@chakra-ui/react";
import Footer from "../../Components/Footer/Footer";
import NavBar from "../../Components/NavBar/NavBar";

//icon
import { ReactComponent as LinkIcon } from "../../../Assets/Icons/link-2.svg";
import SuccessModal from "../../Components/SuccessModal";
import { useEffect, useState } from "react";
import createJob from "../../../Api/Jobs/createJob";
import { CategoryData } from "../../../Data/CategoryData";
import GenreData from "../../../Data/GenreData";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const UpdateJob = () => {
  const [modal_state, set_modal_state] = useState(false);
  const [job_title, set_job_title] = useState("");
  const [job_description, set_job_description] = useState("");
  const [category, set_category] = useState({
    service: "VOCALISTS",
    subservice: "",
  });
  const [genres, set_genres] = useState({
    genere: "National/Indian",
    subgenere: "",
  });
  const [ref_link, set_ref_link] = useState("");
  const [budget, set_budget] = useState([]);
  const [deadline, set_deadline] = useState("");
  const [liveShow, setLiveShow] = useState("");
  const [budgetRange, setBudgetRange] = useState("");
  const [fixedPrice, setFixedPrice] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const toast = useToast();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: "https://backend.udukku.com/jobs/get-job/" + params.id,
      method: "GET",
    })
      .then((res) => {
        console.log(res.data);
        let temp = res.data;

        set_job_title(temp.jobTitle);
        set_category(temp.category);
        set_genres(...temp.genres);
        set_job_description(temp.description);
        set_ref_link(temp.referenceLinks);
        set_deadline(temp.deadLine);
        setLiveShow(temp.liveShow);

        if (temp.budget[0] === temp.budget[1]) {
          setBudgetRange("Fixed");
          set_budget([temp.budget[0], temp.budget[1]]);
          setFixedPrice(temp.budget[0]);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validate = () => {
    let message = "";

    if (!job_title) {
      message = "Job title is required.";
    }

    if (!category.subservice && !message) {
      message = "Sub category is required.";
    }

    if (!genres.subgenere && !message) {
      message = "Sub genre is required.";
    }

    if (!job_description && !message) {
      message = "description is required";
    }

    if (!budgetRange && !message) {
      message = "budget is required";
    }

    if (budgetRange === "Fixed") {
      if (!fixedPrice && !message) {
        message = "fixed price is required";
      }
    } else {
      if (budget.length === 0 && !message) {
        message = "budget range is required.";
      }
    }

    if (liveShow === "" && !message) {
      message = "Performance type is required";
    }

    if (!deadline && !message) {
      message = "Deadline is required";
    }

    if (message) {
      toast({
        title: "Error",
        status: "error",
        description: message,
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return false;
    } else {
      return true;
    }
  };

  const addJob = async () => {
    if (validate()) {
      const final = budgetRange === "Fixed" ? [fixedPrice, fixedPrice] : budget;

      let data = {
        jobId: params.id,
        jobTitle: job_title,
        genres: genres,
        category: category,
        description: job_description,
        referenceLinks: ref_link,
        budget: final,
        deadLine: deadline,
        liveShow: liveShow,
      };

      axios({
        url: "https://backend.udukku.com/jobs/update-job-by-id",
        method: "PATCH",
        data: {
          ...data,
        },
      })
        .then(() => {
          toast({
            title: "success",
            description: "Job Updated Successfully.",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          navigate("/myjobs");
        })

        .catch(() => {
          toast({
            title: "Error",
            description: "Error Updating Job.",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        });
    }

    //set_modal_state(true);
  };

  return (
    <Box mt="7.40vh">
      <NavBar />
      {/* Modals Section */}
      <SuccessModal
        text={"Application Sent Succesfully"}
        state={modal_state}
        changeState={set_modal_state}
      />
      {/*  ------------- */}
      <Box
        px={{ base: "7vw", lg: "13.54vw" }}
        pt="7.40vh"
        minH="calc(100vh - 7.40vh)"
      >
        <Box w={{ base: "80vw", lg: "36.40vw" }}>
          <Text
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "2rem", lg: "2.29vw" }}
          >
            Post A Job
          </Text>
          <Text
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1rem", lg: ".833vw" }}
            mt="1.11vh"
          >
            To post a job, enter the following details for the applicants. The
            clearer you are, the higher are your chances of finding perfect
            musician for your job
          </Text>
          {/* input fields */}
          <Box mt="2.96vh" display={"flex"} flexDir="column" gap="2.22vh">
            {/* title */}
            <Box fontFamily={"Gilroy-SemiBold"} fontSize=".833vw">
              <Text
                fontFamily={"Gilroy-SemiBold"}
                fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
              >
                Job Title*
              </Text>
              <Input
                h="6.48vh"
                borderRadius={{ base: "1rem", lg: "1.04vw" }}
                type="text"
                placeholder="What are you looking for?"
                value={job_title}
                onChange={(e) => {
                  set_job_title(e.target.value);
                }}
              />
            </Box>

            {/* category */}
            <Box
              fontFamily={"Gilroy-SemiBold"}
              fontSize=".833vw"
              display={"grid"}
              gridTemplateColumns="1fr 1fr"
              columnGap={".833vw"}
            >
              <Box>
                <Text
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
                >
                  Category*
                </Text>
                <Select
                  borderRadius={{ base: "1rem", lg: "1.04vw" }}
                  type={"text"}
                  value={category.service}
                  onChange={(e) => {
                    set_category({ ...category, service: e.target.value });
                  }}
                  h="6.48vh"
                  w="100%"
                >
                  {Object.keys(CategoryData).map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Select>
              </Box>
              <Box>
                <Text
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
                >
                  Subcategory*
                </Text>
                <Select
                  h="6.48vh"
                  borderRadius={{ base: "1rem", lg: "1.04vw" }}
                  defaultValue=""
                  placeholder="Select"
                  value={category.subservice}
                  onChange={(e) => {
                    set_category({ ...category, subservice: e.target.value });
                  }}
                >
                  {category.service &&
                    CategoryData[category.service].map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                </Select>
              </Box>
            </Box>

            {/* genres */}
            <Box
              fontFamily={"Gilroy-SemiBold"}
              fontSize=".833vw"
              display={"grid"}
              gridTemplateColumns="1fr 1fr"
              columnGap={".833vw"}
            >
              <Box>
                <Text
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
                >
                  Genres*
                </Text>
                <Select
                  h="6.48vh"
                  borderRadius={{ base: "1rem", lg: "1.04vw" }}
                  defaultValue=""
                  placeholder="Select"
                  value={genres.genere}
                  onChange={(e) => {
                    set_genres({ ...genres, genere: e.target.value });
                  }}
                >
                  {Object.keys(GenreData).map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Select>
              </Box>
              <Box>
                <Text
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
                >
                  Genre Subcategory*
                </Text>
                <Select
                  h="6.48vh"
                  borderRadius={{ base: "1rem", lg: "1.04vw" }}
                  defaultValue=""
                  placeholder="Select"
                  value={genres.subgenere}
                  onChange={(e) => {
                    set_genres({ ...genres, subgenere: e.target.value });
                  }}
                >
                  {!!GenreData[genres.genere] &&
                    GenreData[genres.genere].map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                </Select>
              </Box>
            </Box>

            {/* description */}
            <Box fontFamily={"Gilroy-SemiBold"} fontSize=".833vw">
              <Text
                fontFamily={"Gilroy-SemiBold"}
                fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
              >
                Description*
              </Text>
              <Textarea
                h="19.44vh"
                borderRadius={{ base: "1rem", lg: "1.04vw" }}
                value={job_description}
                onChange={(e) => set_job_description(e.target.value)}
                placeholder="I’d like to know all the things about the vacancy."
              />
            </Box>

            {/* ref link */}
            <Box fontFamily={"Gilroy-SemiBold"} fontSize=".833vw">
              <Text
                fontFamily={"Gilroy-SemiBold"}
                fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
              >
                Reference link
              </Text>
              <Input
                h="6.48vh"
                borderRadius={{ base: "1rem", lg: "1.04vw" }}
                value={ref_link}
                onChange={(e) => set_ref_link(e.target.value)}
                placeholder="https://www.youtube.com/watch?v=YcpIr_PLvUY&list=PL8B3jvnmLJ8YLuyP0-9a8zrM2..."
              />
            </Box>

            {/* budget */}

            <Box fontFamily={"Gilroy-SemiBold"} fontSize=".833vw" w="100%">
              <Text
                fontFamily={"Gilroy-SemiBold"}
                fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
              >
                {" "}
                Budget
              </Text>
              <Select
                h="6.48vh"
                borderRadius={{ base: "1rem", lg: "1.04vw" }}
                onChange={(e) => setBudgetRange(e.target.value)}
                value={budgetRange}
              >
                <option default value={"None"}>
                  Select
                </option>
                <option value={"Fixed"}>Fixed</option>
                <option value={"Negotiable"}>Negotiable</option>
              </Select>
            </Box>

            {budgetRange === "Negotiable" ? (
              <Box
                fontFamily={"Gilroy-SemiBold"}
                fontSize=".833vw"
                display={"grid"}
                gridTemplateColumns="1fr .46vw 1fr"
                columnGap={".833vw"}
              >
                <Box>
                  <Text
                    fontFamily={"Gilroy-SemiBold"}
                    fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
                  >
                    Start Range*
                  </Text>
                  <Input
                    h="6.48vh"
                    borderRadius={{ base: "1rem", lg: "1.04vw" }}
                    type="number"
                    placeholder="Min (₹)"
                    value={budget[0]}
                    onChange={(e) => {
                      set_budget([e.target.value, budget[1]]);
                    }}
                  />
                </Box>
                <Box position={"relative"}>
                  <Text
                    pos="absolute"
                    bottom="3.24vh"
                    transform={"translateY(50%)"}
                  >
                    -
                  </Text>
                </Box>
                <Box>
                  <Text
                    fontFamily={"Gilroy-SemiBold"}
                    fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
                  >
                    End Range*
                  </Text>
                  <Input
                    h="6.48vh"
                    borderRadius={{ base: "1rem", lg: "1.04vw" }}
                    type="number"
                    placeholder="Max (₹)"
                    value={budget[1]}
                    onChange={(e) => set_budget([budget[0], e.target.value])}
                  />
                </Box>
              </Box>
            ) : budgetRange === "Fixed" ? (
              <Box
                fontFamily={"Gilroy-SemiBold"}
                fontSize=".833vw"
                display={"grid"}
              >
                <Box>
                  <Text
                    fontFamily={"Gilroy-SemiBold"}
                    fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
                  >
                    Price
                  </Text>
                  <Input
                    h="6.48vh"
                    borderRadius={{ base: "1rem", lg: "1.04vw" }}
                    type="number"
                    placeholder="Price (₹)"
                    value={budget[0]}
                    onChange={(e) => setFixedPrice(e.target.value)}
                  />
                </Box>
              </Box>
            ) : (
              ""
            )}

            <Box fontFamily={"Gilroy-SemiBold"} fontSize=".833vw" w="100%">
              <Text
                fontFamily={"Gilroy-SemiBold"}
                fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
              >
                Performance Type*
              </Text>
              <Select
                h="6.48vh"
                borderRadius={{ base: "1rem", lg: "1.04vw" }}
                value={liveShow}
                onChange={(e) => setLiveShow(e.target.value)}
              >
                <option default value={"None"}>
                  Select
                </option>
                <option value={true}>Live</option>
                <option value={false}>Production</option>
              </Select>
            </Box>

            {/* upload mp3 */}
            {/* <Box fontFamily={"Gilroy-SemiBold"} fontSize=".833vw" w="100%">
              <Text>Upload MP3</Text>
              <Box
                w="100%"
                h="6.48vh"
                border={"2px dashed #e2e8f1"}
                borderRadius={"1.04vw"}
                px="1.04vw"
                display="flex"
                alignItems={"center"}
                gap=".36vw"
              >
                <LinkIcon
                  style={{
                    width: "1.04vw",
                    height: "1.04vw",
                  }}
                />
                <Text>Browse to add attachments</Text>
              </Box>
            </Box> */}

            {/* deadline */}
            <Box fontFamily={"Gilroy-SemiBold"} fontSize=".833vw" w="100%">
              <Text
                fontFamily={"Gilroy-SemiBold"}
                fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
              >
                Deadline*
              </Text>
              <Select
                h="6.48vh"
                borderRadius={{ base: "1rem", lg: "1.04vw" }}
                value={deadline}
                onChange={(e) => set_deadline(e.target.value)}
              >
                <option default value={"None"}>
                  Select
                </option>
                <option value={"0-7 Days"}>0-7 Days</option>
                <option value={"8-14 Days"}>8-14 Days</option>
                <option value={"15-21 Days"}>15-21 Days</option>
                <option value={"22-30 Days"}>22-30 Days</option>
                <option value={"31-40 Days"}>31-40 Days</option>
                <option value={"41-60 Days"}>41-60 Days</option>
                <option value={"more than 60 Days"}>more than 60 Days</option>
              </Select>
            </Box>
          </Box>

          <Button
            h="6.48vh"
            w="100%"
            borderRadius={{ base: "1rem", lg: "1.04vw" }}
            bg="rgba(246, 84, 14, 1)"
            color="white"
            _hover={{ background: "rgba(246, 84, 14, 1)" }}
            mt="3.70vh"
            mb="7.40vh"
            onClick={() => {
              //set_modal_state(true);
              addJob();
            }}
          >
            Update
          </Button>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default UpdateJob;
