import axios from "axios";
import uploadToCloud from "../../New/Utility/uploadToCloud";
import { Country, State, City } from "country-state-city";

const registerTalentApi = async (data) => {
	try {
		const res = await uploadToCloud(data.avatar);

		// const city = City.getCitiesOfState("IN", data.state).filter((val) => {
		// 	if (val.isoCode === data.city) {
		// 		return true;
		// 	}
		// 	return false;
		// })[0];

		return axios.patch(
			process.env.REACT_APP_BASE_URL + `/user/update-user-by-id/${data.id}`,
			{
				name: data.name,
				userName: data.username,
				mobile: data.wa_number,
				city: data.city,
				state: data.t_state,
				description: data.description,
				genres: data.genre,
				terms: data.term,
				socialMedia: data.social_media,
				services: data.categories,
				gearHighLights: data.gear,
				work: data.work,
				isProfileCompleted: true,
				avatar: res.secure_url,
				role: "Talent",
				startingPrice: data.startingPrice,
			}
		);
	} catch (error) {}
};

export default registerTalentApi;
