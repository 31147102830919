import {
  Box,
  Button,
  List,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import NavBar from "../../Components/NavBar/NavBar";
import { ReactComponent as Searchicon } from "../../../Assets/Icons/search-normal.svg";
import { ReactComponent as Delete } from "../../../Assets/Icons/Delete.svg";
import { ReactComponent as Edit } from "../../../Assets/Icons/edit-2.svg";
import Footer from "../../Components/Footer/Footer";
import getMyJobs from "../../../Api/MyJobs/getMyJobs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import styles from "./myJobs.module.css";
import axios from "axios";

const MyJobs = ({ state }) => {
  const [myJobs, setMyJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [jobId, setJobId] = useState(null);
  const toast = useToast();

  const navigate = useNavigate();
  const getData = async () => {
    setLoading(true);
    try {
      const res = await getMyJobs();
      console.log(res);
      setLoading(false);
      setMyJobs(res.job);
    } catch {}
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = () => {
    setModal(false);
    axios({
      url: "https://backend.udukku.com/jobs/delete-Job/" + jobId,
      method: "DELETE",
    })
      .then((res) => {
        toast({
          title: "Job Deleted Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setJobId(null);
        getData();
      })
      .catch((err) => {
        toast({
          title: "Error Deleting Job",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setJobId(null);
      });
  };

  return (
    <Box display={"flex"} flexDir="column">
      <NavBar />

      <div className={styles.container}>
        <div className={styles.section_head}>
          <h2>My Jobs</h2>
          <button
            onClick={() => {
              navigate("/post-a-job");
            }}
          >
            + Create Job
          </button>
        </div>

        <div className={styles.main}>
          <div className={styles.main_head}>
            <ul className={styles.tabs_container}>
              <li className={styles.active}>All</li>
              <li>Active</li>
              <li>Completed</li>
              <li>Pending</li>
            </ul>

            <div className={styles.main_search_container}>
              <Searchicon />
              <input type="text" placeholder="Search by Job Title" />
            </div>
          </div>

          <div className={styles.main_body}>
            <table>
              <thead>
                <tr>
                  {table_head.map((val, index) => (
                    <th key={index}>{val}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading === false &&
                  myJobs.map((job) => {
                    return (
                      <tr key={job._id}>
                        <td>{job.jobTitle}</td>
                        <td>₹{job.budget[1]}</td>
                        <td></td>
                        <td>
                          <span
                            className={` ${
                              job.status === "active" && styles.searching_talent
                            }`}
                          >
                            {job.status === "active" && "Searching Talent"}
                          </span>
                        </td>
                        <td className={styles.action_btns}>
                          <Edit
                            onClick={() => {
                              navigate("/update-a-job/" + job._id);
                            }}
                          />
                          <Delete
                            onClick={() => {
                              setJobId(job._id);
                              setModal(true);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className={styles.main_footer}>
            <ul className={styles.pagination}>
              <li>{"<"}</li>
              <li className={styles.active}>1</li>
              <li>2</li>
              <li>3</li>
              <li>{">"}</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
      {modal && (
        <div className={styles.modal}>
          <div className={styles.modal_card}>
            <div className={styles.modal_head}>Delete Job</div>
            <div className={styles.modal_content}>
              Are you sure you want to delete this job? This action cannot be
              undone.
            </div>
            <div className={styles.modal_footer}>
              <button
                onClick={() => {
                  setModal(false);
                  setJobId(null);
                }}
              >
                Cancel
              </button>
              <button onClick={handleDelete}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

const table_head = ["Job Title", "Price", "Talent", "Status", "Actions"];

export default MyJobs;
