import React, { useEffect, useRef } from "react";
import { Document, Page } from "react-pdf";

// console.log(pdfjs);
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFThumbnail({ pdfUrl }) {
  return (
    <div>
      <Document file={pdfUrl}>
        <Page pageNumber={1} width={100} />
      </Document>
    </div>
  );
}

export default PDFThumbnail;
