export const GenreData = {
	"National/Indian": [
		"Hindustani Classical",
		"Carnatic Classical ",
		"Bollywood Classical",
		"Bollywood Semi-Classical",
		"Rag-based",
		"Filmi Qawaali",
		"Rabindra Sangeet",
		"Riyaz",
		"Zamzama",
		"Tappa",
		"Tarana",
		"Bollywood Pop",
		"Rap",
		"Dance or DJ music",
		"Indie Rock",
		"Indie Pop",
		"Indian Rock",
		"Indian Pop",
		"Indian Hip Hop",
		"Raga Rock",
		"Indian Jazz",
		"Sufi Rock",
		"Punjabi",
		"Bhojpuri",
		"Chutney",
		"Hindu Devotional",
		"Muslim Devotional",
		"Bhajan",
		"Kirtan",
		'Ghazal',
		'Qawaali',
		'Sufi',
		'Thumri',
		'Bandish',
		'Khyal',
		'Patriotic',
		'Goa Trance',
		'Jazz and Blues',
		'Western Classical Music',
		'Indonesian and Malay music',
		'Thai music',
	],

	"Regional": [
		'Tamang Selo (West Bengal, Sikkim, Nepal)',
		'Bhangra',
		'Giddha',
		'Borgeet',
		'Dandiya',
		'Haryanvi',
		'Himachali',
		'Lavani',
		'Manipuri',
		'Marfa (Hyderabad)',
		'Mizo (Burma)',
		'Odissi',
		'Rabindra Sangeet',
		'Rajasthani',
		'Sufi folk rock',
		'Sufi rock',
		'Uttarakhandi',
		'Bhangra',
		'Lungi',
	],

	"International":
		[
			'Reggaeton',
			'Rock and Roll',
			'Techno',
			'Techno pop',
			'EDM',
			'Electronic',
			'Heavy Rock',
			'Heavy Metal',
			'House',
			'Hip Hop',
			'Rock',
			'Pop',
			'Blues',
			'Chill',
			'Dubstep',
			'Gospel',
			'Indie Pop',
			'Jazz',
			'Pop-Rock',
			'R&B - Soul',
			'Singer-songwriter',
			'Electric Pop',
			'Country',
			'Folk/ Acoustic',
			'Funk',
			'Heavy',
			'Latin',
			'Reggae',
			'Soft Pop',
			'Trance',
			'Trap',
			'Afrobeat',
			'Americana',
			'Choral',
			'Classical',
			'Tropical/Dancehall',
			'World',
		]
}

export default GenreData;