import {
	Box,
	Button,
	Input,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
//Icon
import { ReactComponent as BudgetIcon } from "../../../../Assets/Icons/dollar-circle-transparent.svg";

const BudgetFilter = ({ MainStart, MainEnd, setMainStart, setMainEnd }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [start_price, set_start_price] = useState(MainStart);
	const [end_price, set_end_price] = useState(MainEnd);
	const [btn_status, set_btn_status] = useState(true);
	const [done, set_done] = useState(false);

	useEffect(() => {
		if (MainStart === "" || MainEnd === "") {
			set_btn_status(true);
			set_done(false);
		} else {
			set_btn_status(false);
			set_done(true);
		}
	}, [MainStart, MainEnd]);

	const handleSubmit = () => {
		onClose();
	};

	return (
		<Popover isOpen={isOpen} onClose={onClose} closeOnBlur={true}>
			<PopoverTrigger>
				<Box
					fontFamily={"Gilroy-SemiBold"}
					fontSize={{ base: "1.5rem", lg: ".833vw" }}
					w={{ base: "90%", lg: "fit-content" }}
					h="6.48vh"
					border="1.5px solid #F0F0F0"
					display={"flex"}
					alignItems="center"
					justifyContent={{ base: "start", lg: "center" }}
					borderRadius="15px"
					gap={{ base: "1rem", lg: ".31vw" }}
					cursor={"pointer"}
					_hover={{
						background: "rgba(8, 32, 50, 1)",
						color: "white !important",
						svg: {
							fill: "white !important",
						},
					}}
					px="2.08vw"
					onClick={onOpen}
					bg={done ? "rgba(8, 32, 50, 1)" : "white"}
					color={done ? "white" : "black"}
				>
					<BudgetIcon
						className="genre-category-icons2"
						style={{
							fill: done ? "white" : "#082032",
							width: "1.25vw",
							height: "1.25vw",
						}}
					/>

					<Text fontFamily={"Gilroy-SemiBold"}>
						{done ? `$${MainStart} - $${MainEnd}` : "Budget"}
					</Text>
				</Box>
			</PopoverTrigger>
			<PopoverContent borderRadius={"1.04vw"}>
				<PopoverArrow />
				<PopoverBody
					display={"grid"}
					gridTemplateColumns={"1fr .1fr 1fr"}
					columnGap=".4vw"
					rowGap={".7vh"}
				>
					<Box
						display={"flex"}
						flexDir="column"
						alignItems={"center"}
						fontFamily="Gilroy-SemiBold"
					>
						<Text
							fontFamily={"Gilroy-SemiBold"}
							fontSize={{ base: "4.5vw", lg: ".833vw" }}
						>
							Start Price
						</Text>
						<Input
							h="6.48vh"
							borderRadius={"1.04vw"}
							type="number"
							value={MainStart}
							fontSize={"1.9rem !important"}
							onChange={(e) => {
								setMainStart(e.target.value);
							}}
						></Input>
					</Box>
					<Box position="relative">
						<Text
							position={"absolute"}
							textAlign={"center"}
							bottom="3.24vh"
							fontSize={"1.9rem !important"}
							left="50%"
							transform={"translateY(50%) translateX(-50%)"}
						>
							-
						</Text>
					</Box>
					<Box
						display={"flex"}
						flexDir="column"
						alignItems={"center"}
						fontFamily="Gilroy-SemiBold"
					>
						<Text
							fontFamily={"Gilroy-SemiBold"}
							fontSize={{ base: "4.5vw", lg: ".833vw" }}
						>
							End Price
						</Text>
						<Input
							h="6.48vh"
							borderRadius={"1.04vw"}
							type="number"
							fontSize={"1.9rem !important"}
							value={MainEnd}
							onChange={(e) => {
								setMainEnd(e.target.value);
							}}
						></Input>
					</Box>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default BudgetFilter;
