import { Box, Text } from "@chakra-ui/react";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Udukku | T&C";
  });

  return (
    <Box mt="7.40vh">
      <NavBar />
      <Box px={{ base: "7vw", lg: "13.54vw" }} py="80px">
        <Text
          fontFamily={"Gilroy-Bold"}
          fontSize={{ base: "2rem", lg: "2.29vw" }}
        >
          Marketplace Terms And Conditions
        </Text>
        <Text
          fontFamily={"Gilroy-Medium"}
          fontSize={{ base: "1.2rem", lg: "1.04vw" }}
        >
          These TERMS AND CONDITIONS (“Terms”) set out the rights and
          obligations of the Musician or Musician (“You”) regarding the use of
          this UDUKKU (“Company”)’s Platform.
        </Text>
        <Text
          fontFamily={"Gilroy-Medium"}
          fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          mt="16px"
        >
          Please read these terms and conditions carefully before using Our
          Website.
        </Text>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            1. Interpretation and Definition
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            {" "}
            1.1. Interpretation
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or plural.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            1.2. Definitions
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            For these Terms and Conditions:
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Affiliate means an entity that controls is controlled by or is under
            common control with a party, where "control" means ownership of 50%
            or more of the shares, equity interest, or other securities entitled
            to vote for the election of directors or other managing authority.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Company (referred to as either "the Company," "We," "us," or "our"
            in this Agreement) refers to UDUKKU, of [ENTER ADDRESS].
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Customer means a person who purchases Musician’s Services through
            UDUKKU’s Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Musician means an entity or individual, who offers their Services on
            UDUKKU’s Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Musician’s Services refer to the services offered by the Musician in
            UDUKKU’s Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Orders mean a request by the Customer to purchase Musician’s
            Services.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Visitor means the entity accessing UDUKKU’s Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Website or Platform refers to UDUKKU’s Website, accessible from
            https://www.udukku.com/
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            You (unless stated otherwise in the Agreement) means the Musician,
            which is a legal entity accessing the Company’s Website and
            Websites.
          </Text>
        </Box>

        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            2. Acknowledgment
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            {" "}
            2.1 These are the Terms and Conditions governing the use of our
            Website and the Agreement that operates between you and the Company.
            These Terms and Conditions set out the rights and obligations of all
            users regarding the use of the Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            2.2 Your access to and use of the Website is conditioned on your
            acceptance of and compliance with these Terms and Conditions. The
            terms of this Agreement applies to Your access or use of our
            Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            2.3 You agree to be bound by these Terms and Conditions. If you
            disagree with any part of these Terms and Conditions, then you may
            not use our Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            2.4 You represent that you are legally authorized to do business,
            and that you adhere to all relevant laws.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            2.5 Your access to and use of the Website is also conditioned on
            your acceptance of and compliance with the Privacy Policy of the
            Company. Our Privacy Policy describes our policies and procedures on
            the collection, use, and disclosure of your personal information
            when you use the Website or the Website and tells you about your
            privacy rights and how the law protects You. Please read our Privacy
            Policy carefully before using our Website.
          </Text>
        </Box>

        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            3. Website
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            {" "}
            3.1 Our Website{" "}
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            3.1.1 UDUKKU is an online music market place.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            3.1.2 We may change any detail in our Platform at any time in our
            sole discretion. The changes will be effective upon posting of such
            updates on the Website. If you do not agree to any posted changes,
            do not continue to use our Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            3.2 Musician’s Services. The musician’s services are provided in a
            listing on our Website.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            4. User Accounts
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            {" "}
            4.1 The first step to obtain the best use of our Platform is
            creating of a Musician Account.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.1.1. To create an account with us, you must provide us with all
            the information that is requested.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.1.2. We also provide an option for account creation via
            third-party sign-on Website like Google or Facebook.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.1.3. You have the right to cancel your registration at any time.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.1.4. In the event you represent a legal entity in the creation of
            an account, your actions shall be deemed to bind the entity. You
            shall be liable for misrepresentation if you hold out as a
            representative of such entity. This may lead to a termination of
            your account.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.1.5. You shall be required to provide a unique password to
            guarantee security for your account.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.1.6. You are responsible for safeguarding the password that You
            use to access the Service and for any activities or actions under
            Your password, whether Your password is with our Application or a
            Third-Party Social Media Service.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.1.7. You agree not to disclose your password to any third party.
            You must notify us immediately upon becoming aware of any breach of
            security or unauthorized use of your account.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.1.8. You may not use as a username the name of another person or
            entity, or that is not lawfully available for use, a name or
            trademark that is subject to any rights of another person or entity
            other than You without appropriate authorization, or a name that is
            otherwise offensive, vulgar or obscene
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.2 When submitting registering your account, you shall provide all
            the required information.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            You agree:
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            i. to provide current, complete, and accurate information about you
            in your profile as prompted by the Company’s online registration
            form (“Registration Data”) ii. to maintain and update your
            Registration Data as required to keep such information current,
            complete and accurate.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            iii. You warrant that your Registration Data is and will continue to
            be accurate and current, and that you are authorized to provide your
            Registration Data.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            iv. You authorize the Company to verify your Registration Data at
            any time.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            v. If any Registration Data that you provide is untrue, inaccurate,
            not current, or incomplete, the Company retains the right, in the
            Company’s sole discretion, to suspend or terminate your
            participation in the Company’s Platform.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.3 Chats
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.3.1. Our Website makes it possible for the Musician to communicate
            with the Customer.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.3.2. The communication is hereby limited only to such matters
            concerning the sale and/or purchase of the Musician’s Services.
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.3.3. The Platform’s Admin may monitor and record all chat
            conversations between you and the Customer for quality and dispute
            resolution purpose.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.3.4. We shall treat the contents of your chats with utmost
            confidentiality.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.3.5. You are strictly prohibited from sharing personal or
            sensitive information with the Users of the Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.4 Notifications
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.4.1 We may communicate to you at will, through any contact details
            in your account.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.4.2 You agree to receive notifications from us.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.4.3 You shall have an option of disabling the notifications.
            However, you may miss out on important information concerning your
            account.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.4.4 We shall not be liable for any loss or damage you suffer
            because of disabling the notifications
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.5 Termination of your account
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.5.1. Your account may be suspended by us if: (a) fraud is
            committed through your account, (b) there is reasonable belief that
            your account poses a risk to other Platform users, (c) you violate
            either this Agreement or any other Agreement or policy entered
            between both Parties in this Agreement, and (d) you do not respond
            to communication from us and other users.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.5.2. Any usage of Your account to violate a provision of law,
            harass other users or third parties, sending spam to other users, or
            commit other illegal acts shall amount to a prohibitive use, and
            Your account shall be terminated forthwith.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            4.5.3. You may also terminate your account following a notice to us.
            It might take up to 10 business days on our side to complete the
            request.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            5. Listings and Musician’s Services
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            You” under this Section 5, refers to the Musician.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            5.1. Listings
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            You retain all of Your rights to any Content and/or Services you
            submit, post, or display on or through the Website, and you are
            responsible for protecting those rights.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            You represent and warrant that: (i) the Content is Yours (You own
            it) or You have the right to use it and grant us the rights and
            license as provided in these Terms, and (ii) the posting of Your
            Content on or through the Application does not violate the privacy
            rights, publicity rights, copyrights, contract rights or any other
            rights of any person.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            We retain all of rights to display your Postings on or through our
            Website. You agree that this license includes the right for us to
            make your Content available to other users of the Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            5.2. Acceptance of, or payment for, the Services delivered under a
            Purchase Order shall not constitute a waiver by Customer of any of
            the warranties of Musician, either express or implied, and shall not
            be deemed to alter or affect the obligations of Musician or rights
            of Customer under any of such warranties, either express or implied,
            nor shall acceptance of, or payment for, any Musician’s Services
            constitute a waiver of any of Customer’s rights or remedies either
            contained herein, or in law or at equity.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            5.3. Abiding by the law
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            You represent and warrant that the Services comply with all
            applicable “Laws” (meaning all applicable laws, regulations, legal
            requirements, and generally accepted industry standards), including
            Laws related to consumer safety, and pricing, in connection with
            this Agreement.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Notably, you represent that the Services (i) shall be of the kind
            and quality specified herein, free from fault in design, workmanship
            and material, (ii) shall be of merchantable quality, (iii) are
            suitable and fit for the purpose for which they shall be used, (iv)
            shall perform in accordance with the specifications and drawings, if
            any, provided by the Customer or otherwise referred to, or
            incorporated, in the Purchase Order, (v) shall not constitute an
            infringement or contributory infringement of any patent or any
            copyright or trademark, or violation of any trade secret when sold
            or used for their ordinary intended purpose.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            6. Pricing, Fees, Payment and discount
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.1. Pricing
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            The Musician shall set the price of all Musician’s Services.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.2. Fees
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            The Company shall charge fees for the use of the Company’s Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3. Payment
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.1. You hereby appoint the Company as Your agent for the sole and
            express purpose of collecting payments.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.2. Payments shall be made in Indian Rupees.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.3. The Company will process all of the Customers’ orders and
            collect all amounts due from them on the Musician’s behalf.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.4. We shall make all payments due to the Musician less any
            commission for the sale of Musician’s Services; penalties/ or any
            other amounts applicable.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.5. The Company will process all payments to the Musician using
            EFT (Electronic Fund Transfer) system. We shall make the payment to
            the bank account designated by the Musician, not later than 15 days
            after the sale of a Musician’s Services.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.6. We shall keep a record of all payment information and/or
            statement(s).
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.7. For the sake of clarity, a Customer’s order is deemed
            complete the earlier of: (i) payment for such item, or (ii) its
            delivery to the Delivery Point.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.8. We take advance payments for media and software.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.9. You shall provide us your credit card and/or bank account
            details for payment purposes. You acknowledge that we shall not be
            liable for any failure to make and/or receive payments in the event
            you provide us with incomplete or inaccurate bank account details.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.10. In the event we owe the Musician any sum, such sum may be
            used as a set off against any sum the Musician owes us; or may be
            used in claims against us following Musician’s non-performance of
            any obligation(s) herein.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            6.3.11. The Musician shall bear the responsibility of settling all
            taxes due to Musician, under applicable law.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            7. Order cancellation
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Cancellation shall be governed by our Refund and Cancellation
            Policy.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            8. Copyright Policy
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            8.1. Intellectual Property Infringement
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            8.1.1. We respect the intellectual property rights of others. It is
            Our policy to respond to any claim of the infringement of a
            copyright or other intellectual property infringement of any person.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            8.1.2. If You are a copyright owner, and You believe that the
            copyrighted work has been copied in a way that constitutes copyright
            infringement that is taking place through the Service, You must
            submit Your notice in writing to the attention of our copyright
            agent via email at and include in your notice a detailed description
            of the alleged infringement.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            8.1.3. You may be held accountable for damages (including costs and
            attorneys' fees) for misrepresenting that any content is infringing
            your copyright.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            9. Intellectual Property
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            {" "}
            9.1. The Website and its original Content, features, and
            functionality are and will remain the exclusive property of the
            Company and its licensors.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            9.2. The Service is protected by copyright, trademark, and other
            laws of India.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            9.3. Our trademarks may not be used without the prior written
            consent of the Company.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            9.4. If You wish to use copyrighted material from the service for
            Your purposes that go beyond fair use, You must obtain permission
            from the copyright owner.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            10. Insurance
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            10.1. Musicians must get an insurance cover for their business.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            10.2. The type of insurance, parties/ items covered, and the costs
            shall be at the Musician’s discretion.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            11. Warranties
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            {" "}
            The Musician warrants and represents that:
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            11.1. The Musician’s Services will: strictly conform to the
            specifications, performance criteria, and other descriptions
            referred to or provided on the Platform.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            11.2. All information, including but not limited to all information
            furnished to us with regards to the Musician’s Services is accurate
            and up-to-date.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            11.3. It shall not host, display, upload, modify, publish, transmit,
            update or share any information which infringes any Intellectual
            Property rights.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            11.4. The Musician will strictly adhere to all applicable laws of
            India.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            11.5. That it acknowledges that we may at any point require the
            Musician to provide any financial, business or personal information
            for any purpose whatsoever and the Seller shall provide the same to
            us within seven (7) Business Days of such request being made.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            12. Disclaimer
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            2.1. The information contained on the service is for general
            information purposes only.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.2. The Company assumes no responsibility for errors or omissions
            in the service.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.3. In no event shall the Company be liable for any special,
            direct, indirect, consequential, or incidental damages or any
            damages whatsoever, whether in an action of contract, negligence or
            other tort, arising out of or in connection with the use of the
            service.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.4. The Company reserves the right to make additions, deletions,
            or modifications to the contents on the Website at any time without
            prior notice.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.5. The Company does not warrant that the Website is free of
            viruses or other harmful components.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.6. The information given by the Website is for general guidance
            on matters of interest only. Even if the Company takes every
            precaution to ensure that the content of the Website is both current
            and accurate, errors can occur. Plus, given the changing nature of
            laws, rules, and regulations, there may be delays, omissions or
            inaccuracies in the information contained on the service. The
            Company is not responsible for any errors or omissions, or the
            results obtained from the use of this information.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.7. The information on the Website is provided with the
            understanding that the Company is not herein engaged in rendering
            legal, accounting, tax, or other professional advice and Website. As
            such, it should not be used as a substitute for consultation with
            professional accounting, tax, legal or other competent advisers.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.8. In no event shall the Company or its affiliates be liable for
            any special, incidental, indirect, or consequential damages
            whatsoever arising out of or in connection with your access or use
            or inability to access or use the Website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.9. All information in the service is provided "as is," with no
            guarantee of completeness, accuracy, timeliness or of the results
            obtained from the use of this information, and without warranty of
            any kind, express or implied, including, but not limited to
            warranties of performance, merchantability, and fitness for a
            particular purpose.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            12.10. The Company will not be liable to You, or anyone else for any
            decision made or action taken in reliance on the information given
            by the Website or for any consequential, special or similar damages,
            even if advised of the possibility of such damages.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            13. Limitation of Liability
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            13.1. Neither udukku nor its agents, directors, Shareholders,
            members, partners, managers, officers, Employees, attorneys, or
            affiliates shall have any Liability for, and you hereby waive,
            release, and agree Not to sue any of them upon, any claim for any
            special, Indirect, incidental, or consequential damages suffered Or
            incurred by you in connection with, arising out of, Or in any way
            related to, this agreement or your use of Any of our website even if
            we have been advised of the Possibility of damages. If you are
            dissatisfied with the Website, or any content contained therein,
            your sole and Exclusive remedy is to discontinue using the platform
            And our website.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            13.2. This limitation applies to the extent permitted by Law
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            14. Governing Law
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            The laws of India, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. Your use of the
            Website may also be subject to other local, state, national, or
            international laws.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            15. Dispute Resolution
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            If there is any dispute under this Agreement, You grant us the right
            to talk on your behalf. We will work with both parties to the
            dispute to arrive at a settlement.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            If You have any concerns or disputes about the Platform, You agree
            first to try to resolve the dispute informally by contacting the
            Company. If that fails, the dispute will be settled through binding
            arbitration and not in a court of law.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            16. Severability and Waiver
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            16.1. Severability
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law. The remaining provisions will
            continue in full force and effect.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            16.2. Waiver
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Terms shall not
            effect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall be the waiver of a
            breach constitute a waiver of any subsequent breach.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            17. Changes to these Terms and Conditions
          </Text>

          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is a material, we will make
            reasonable efforts to provide at least 30 days' notice prior to any
            new terms taking effect. What constitutes a material change will be
            determined at Our sole discretion.
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            By continuing to access or use Our Service after those revisions
            become effective, You agree to be bound by the revised terms. If You
            do not agree to the new terms, in whole or in part, please stop
            using the Website and the Service.
          </Text>
        </Box>
        <Box>
          <Text
            mt="52px"
            fontFamily={"Gilroy-Bold"}
            fontSize={{ base: "1.5rem", lg: "1.66vw" }}
          >
            18. Contact Us
          </Text>
          <Text
            mt="16px"
            fontFamily={"Gilroy-Medium"}
            fontSize={{ base: "1.2rem", lg: "1.04vw" }}
          >
            If You have any questions about these Terms and Conditions, You can
            contact us:
          </Text>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default TermsAndConditions;
