import {
  Box,
  Image,
  Text,
  Button,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import NavBar from "../../Components/NavBar/NavBar";

// icons
import { ReactComponent as TaskIcon } from "../../../Assets/Icons/task-square.svg";
import { ReactComponent as PeopleIcon } from "../../../Assets/Icons/profile-2user.svg";
import { ReactComponent as MoneyIcon } from "../../../Assets/Icons/dollar-circle-transparent.svg";
import { ReactComponent as ExportIcon } from "../../../Assets/Icons/export.svg";
import JobSearchCard from "../../Components/jobSearchCard/JobSearchCard";
import { AccessAuthContext } from "../../Context/AuthContext";
import getAllUsers from "../../../Api/User/getAllUsers";
import { useSelector } from "react-redux";
import { getUserInfoById } from "../../../Api/User/getUserById";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import styles from "../../../Styles/dashboard.module.css";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,
} from "react-share";
import getJobs from "../../../Api/Jobs/getJobsApi";
import { useToast } from "@chakra-ui/react";

// dummy
const d_data = [
  {
    jobPostedBy: {
      avatar: "https://source.unsplash.com/random",
      name: "CFT LABS",
      city: "Rajasthan",
    },
    jobTitle: "Looking for an experienced Beat Maker",
    category: [
      { subService: "Female Vocalist or Singer" },
      { subService: "Full Instrument Productions" },
    ],
    description:
      "I am looking for someone with expertise in making beats for animations and logo reveals.",
    deadLine: "1-7 days",
    budget: [5000],
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const { userId } = AccessAuthContext();
  const { user } = useSelector((state) => ({ ...state }));
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [allUser, setAllUser] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [jobs, setJobs] = useState([]);
  const toast = useToast();

  const getData = async () => {
    try {
      const res = await getJobs();
      setLoading(false);

      let temp = res.data.filter((val) => {
        for (let i = 0; i < user.services.length; i++) {
          if (user.services[i].category === val.category) {
            return true;
          }
          return false;
        }
      });
      setJobs(temp);
    } catch (error) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAllUsers().then((res) => {
        const filteredUser = res.user.filter((user) => user._id === userId);
        setAllUser(filteredUser[0]);
      });
    } else {
      navigate("/");
    }
  }, []);

  const id = user?.userId;

  const copy = () => {
    // Get the text field
    var copyText = document.getElementById("profile_link");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    toast({
      title: "Copied!",
      description: "Profile link is copied.",
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };

  useEffect(() => {
    setLoading(true);
    getUserInfoById(id).then((res) => {
      setUserData(res.data);
    });
    setLoading(false);
  }, [id]);

  return (
    <>
      <Box mt="7.40vh">
        <NavBar />
        <Box
          px={{ base: "7vw", lg: "13.54vw" }}
          py={{ base: "5rem", lg: "10rem" }}
          minH="calc(100vh - 7.40vh)"
        >
          <Text
            fontSize={{ base: "1.5rem", md: "4rem", lg: "2.29vw" }}
            fontFamily="Gilroy-Bold"
          >
            Welcome back,
            <br /> {user?.name}
          </Text>

          <Modal size="lg" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderRadius="22px">
              <ModalHeader>Share Your Profile</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box display={"flex"} gap="10px">
                  <Input
                    type="text"
                    fontFamily={"Gilroy-Bold"}
                    fontSize={"1rem"}
                    id="profile_link"
                    value={`https://udukku.com/${user?.userName}`}
                    flexGrow={1}
                  ></Input>
                  <Button onClick={copy}>Copy</Button>
                </Box>
                <Box
                  display={"flex"}
                  marginTop={"20px"}
                  paddingBottom={"19px"}
                  justifyContent={"space-around"}
                >
                  <FacebookShareButton url="facebook.com">
                    <FacebookIcon sx={{ color: "#F6540E", fontSize: "3rem" }} />
                  </FacebookShareButton>

                  <WhatsappShareButton url="whatsapp.com">
                    <WhatsAppIcon sx={{ color: "#F6540E", fontSize: "3rem" }} />
                  </WhatsappShareButton>

                  <LinkedinShareButton url="linkedin.com">
                    <LinkedInIcon sx={{ color: "#F6540E", fontSize: "3rem" }} />
                  </LinkedinShareButton>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Box
            w="100%"
            h="fit-content"
            mt="3.70vh"
            display={{ md: "grid", sm: "block" }}
            gridTemplateColumns={"1fr 1fr 1fr"}
            columnGap={".8333vw"}
          >
            <Box
              className="dashboard-box"
              w="100%"
              h={{ lg: "16.29vh" }}
              border={"2px solid #f0f0f0"}
              borderRadius={{ base: "15px", lg: "1.66vw" }}
              px={{ base: "2.5vw", lg: "1.66vw" }}
              py={{ lg: "2.96vh" }}
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box
                display={{ base: "flex" }}
                alignItems={{ base: "center" }}
                gap={{ base: "10px" }}
              >
                <Text
                  fontFamily={"Gilroy-Bold"}
                  fontSize={{ base: "2rem", md: "3rem", lg: "2.29vw" }}
                >
                  {userData.jobsCompleted}
                </Text>
                <Text
                  className="lyrics-heading-1"
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={{ base: "6px", md: "1.5rem", lg: "1.04vw" }}
                >
                  Jobs Completed
                </Text>
              </Box>
              <Box
                w={{ base: "10vw", lg: "3.54vw" }}
                h={"3.54vw"}
                bg="rgba(246, 84, 14, .1)"
                borderRadius={"1.04vw"}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
              >
                <TaskIcon className={styles.icons} />
              </Box>
            </Box>
            <Box
              className="dashboard-box"
              w="100%"
              h={{ lg: "16.29vh" }}
              border={"2px solid #f0f0f0"}
              borderRadius={{ base: "15px", lg: "1.66vw" }}
              px={{ base: "2.5vw", lg: "1.66vw" }}
              py={{ lg: "2.96vh" }}
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box
                display={{ base: "flex" }}
                alignItems={{ base: "center" }}
                gap={{ base: "10px" }}
              >
                <Text
                  fontFamily={"Gilroy-Bold"}
                  fontSize={{ base: "2rem", md: "3rem", lg: "2.29vw" }}
                >
                  {userData?.repeatedBuyer}
                </Text>
                <Text
                  className="lyrics-heading-1"
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={{ base: "6px", md: "1.5rem", lg: "1.04vw" }}
                >
                  Repeatitive Buyers
                </Text>
              </Box>
              <Box
                w={{ base: "10vw", lg: "3.54vw" }}
                h={"3.54vw"}
                bg="rgba(246, 84, 14, .1)"
                borderRadius={"1.04vw"}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
              >
                <PeopleIcon className={styles.icons} />
              </Box>
            </Box>
            <Box
              className="dashboard-box"
              w="100%"
              h={{ lg: "16.29vh" }}
              border={"2px solid #f0f0f0"}
              borderRadius={{ base: "15px", lg: "1.66vw" }}
              px={{ base: "2.5vw", lg: "1.66vw" }}
              py={{ lg: "2.96vh" }}
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box
                display={{ base: "flex" }}
                alignItems={{ base: "center" }}
                gap={{ base: "10px" }}
              >
                <Text
                  fontFamily={"Gilroy-Bold"}
                  fontSize={{ base: "2rem", md: "3rem", lg: "2.29vw" }}
                >
                  ₹{userData?.totalEarn}
                </Text>
                <Text
                  className="lyrics-heading-1"
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={{ base: "6px", md: "1.5rem", lg: "1.04vw" }}
                >
                  Total Earn
                </Text>
              </Box>
              <Box
                w={{ base: "10vw", lg: "3.54vw" }}
                h={"3.54vw"}
                bg="rgba(246, 84, 14, .1)"
                borderRadius={"1.04vw"}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
              >
                <MoneyIcon
                  className={styles.icons}
                  style={{
                    fill: "rgba(246, 84, 14, 1)",
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* profile share option */}
          <Box
            mt="2.22vh"
            w="100%"
            h="100%"
            border="2px solid #F0F0F0"
            borderRadius={{ base: "15px", lg: "1.66vw" }}
            px={{ base: "1.5rem", lg: "1.25vw" }}
            py="2.22vh"
            display={{ base: "grid", lg: "flex" }}
            alignItems="center"
            overflow={"hidden"}
            gap={{ base: ".5rem", lg: "0" }}
          >
            <Text
              className={styles.text}
              fontFamily={"Gilroy-Bold"}
              fontSize={{ base: "2rem", md: "3rem", lg: "1.45vw" }}
            >
              Hire me on Udukku
            </Text>
            <Image
              src={user?.avatar}
              h={{ base: "10vh", lg: "30vh" }}
              w={{ base: "auto", lg: "30vh" }}
              borderRadius={"10%"}
              objectFit="cover"
              // objectPosition={"50% 50%"}
            />
            <Box ml={{ base: "0", lg: "1.14vw" }} flexGrow={1}>
              <Text
                className={styles.text_2}
                fontFamily={"Gilroy-Bold"}
                fontSize={{ base: "2rem", md: "3rem", lg: "1.45vw" }}
              >
                Hire me on Udukku
              </Text>

              <Text
                fontFamily={"Gilroy-Medium"}
                fontSize={{ base: "1rem", md: "1.5rem", lg: ".833vw" }}
                whiteSpace={"pre-wrap"}
                w={{ base: "100%", lg: "45vw" }}
              >
                {userData?.description}
              </Text>
            </Box>
            <Box flexGrow={1}></Box>

            {/* <FacebookShareButton url="facebook.com" > */}
            <Box
              display={"flex"}
              flexDir={{ base: "row", lg: "column" }}
              alignItems={"center"}
              justifyContent={{ base: "center", lg: "" }}
              cursor="pointer"
              onClick={onOpen}
              flexShrink={0}
              gap={{ base: ".5rem", lg: "" }}
            >
              <ExportIcon className={styles.share_icon} />
              <Text
                fontSize={{
                  base: "1.5rem",
                  md: "1.5rem",
                  lg: ".833vw",
                  whitespace: "nowrap",
                }}
                lineHeight={{ base: "1.6rem" }}
                fontFamily="Gilroy-SemiBold"
                color="rgba(246, 84, 14, 1)"
              >
                Share Profile
              </Text>
            </Box>
            {/* </FacebookShareButton> */}
          </Box>

          {/* jobs suggestion section */}
          <Box mt="5.55vh">
            <Text
              fontFamily={"Gilroy-Bold"}
              fontSize={{ base: "1rem", md: "2rem", lg: "2.29vw" }}
            >
              Jobs You May Like
            </Text>
            <Box mt="2.22vh" display={"flex"} flexDir={"column"} gap={"20px"}>
              {jobs.length !== 0 ? (
                jobs.map((data, index) => {
                  return <JobSearchCard data={data} key={index} />;
                })
              ) : (
                <Box
                  width={"100%"}
                  height={"100px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  fontSize={"18px"}
                  color="rgba(0,0,0,.3)"
                >
                  No Jobs Found
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};
export default Dashboard;
