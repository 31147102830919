import styles from "../../Styles/button.module.css";

const CustomButton = (props) => {
  return (
    <button
      className={`${styles.button} ${
        props.type === "outlined" ? styles.outlined : styles.filled
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default CustomButton;
