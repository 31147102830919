import React from "react";
import { Box, Button, Text, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import registerTalentApi from "../../../Api/Registration/registerTalentApi";
import updateUserApi from "../../../Api/User/updateUserApi";
import checkForUserName from "../../Utility/checkForUserName";
import TalentRegistrationPersonalInfo from "./TalentRegistrationPersonalInfo";
import TalentRegistrationProfessionalInfo from "./TalentRegistrationProfessionalInfo";
import { useToast } from "@chakra-ui/react";
import { getUserInfoById } from "../../../Api/User/getUserById";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { currentUser } from "../../../Api/Auth/activateUser";
import uploadImage from "../../../Api/Lyrics/uploadImage";
import { Country, State, City } from "country-state-city";
import { capitalizeFirstLetter } from "../../Utility/utils";

function isValidURL(str) {
  if (
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
      str
    )
  ) {
    return { status: true, error: "" };
  } else {
    return { status: false, error: "social media should be a link." };
  }
}

const TalentRegistration = () => {
  const [initial_load, setInitialLoad] = useState(true);
  const [loading, set_loading] = useState(false);
  const [editPage, setEditPage] = useState("");
  const [fname, set_fname] = useState("");
  const [username, set_username] = useState("");
  const [check_username_availability, set_check_username_availability] =
    useState(false);
  const [wa_number, set_wa_number] = useState("");
  const [city, set_city] = useState("");
  const [ustate, set_state] = useState("");
  const [description, set_description] = useState("");
  const [avatar, set_avatar] = useState("");
  const [avatar_object, set_avatar_object] = useState("");
  const [genre, set_genre] = useState([]);
  const [gear, set_gear] = useState([]);
  const [social_media, set_social_media] = useState([]);
  const [work, set_work] = useState([]);
  const [term, set_term] = useState([" "]);
  const [categories, set_categories] = useState([]);
  const [accept, set_accept] = useState(false);

  const { user } = useSelector((state) => ({ ...state }));

  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const dispatch = useDispatch();

  const id = user?.userId;

  useEffect(() => {
    if (id || location.state.id) {
      set_loading(true);
      getUserInfoById(id ? id : location.state.id)
        .then((res) => {
          set_fname(res.data.name);
          set_username(res.data.username);
          set_wa_number(res.data.mobile);
          set_city(res.data.city);
          set_state(res.data.state);
          set_description(res.data.description);
          set_avatar(res.data.avatar);
          set_genre(
            res.data.genres.length === 0
              ? [
                  {
                    genre: "",
                    subGenre: "",
                  },
                ]
              : res.data.genres
          );
          set_gear(
            res.data.gearHighLights.length === 0
              ? [
                  {
                    gear: "",
                    gearHighLights: "",
                  },
                ]
              : res.data.gearHighLights
          );
          set_categories(
            res.data.services.length === 0
              ? [
                  {
                    category: "",
                    subCategory: "",
                    serviceStargingPrice: "",
                  },
                ]
              : res.data.services
          );
          set_social_media(
            res.data.socialMedia.length === 0
              ? [
                  {
                    plat: "",
                    link: "",
                  },
                ]
              : res.data.socialMedia
          );
          set_term(res.data.terms.length === 0 ? [""] : res.data.terms);
          setInitialLoad(false);
          set_loading(false);
        })
        .catch(() => {
          alert("error occured");
          setInitialLoad(false);
          set_loading(false);
        });
    }
  }, [id]);

  const checkFields = () => {
    if (!fname) return { status: false, error: "Name is required." };
    if (!username) return { status: false, error: "Username is required." };
    if (!wa_number)
      return { status: false, error: "Whatsapp number is required." };
    if (!city) return { status: false, error: "City is required." };
    if (!ustate) return { status: false, error: "State is required." };
    if (!description)
      return { status: false, error: "Description is required" };
    else {
      if (description.length < 120)
        return {
          status: false,
          error: "Description require at least 120 words.",
        };
    }

    // check categories
    for (let i = 0; i < categories.length; i++) {
      if (!categories[i].category)
        return {
          status: false,
          error: "Please select the preferred category.",
        };
      if (categories[i].serviceStargingPrice.toString() === "")
        return {
          status: false,
          error: "Please select the service starting price in category.",
        };
      if (categories[i].subCategory === "")
        return {
          status: false,
          error: "Please select the sub-category in category.",
        };
    }

    // check genre
    for (let i = 0; i < genre.length; i++) {
      if (genre[i].genre === "")
        return {
          status: false,
          error: "Genre is required.",
        };
      if (genre[i].subGenre === "")
        return {
          status: false,
          error: "Please select the sub-genre genre.",
        };
    }

    // check gear
    for (let i = 0; i < gear.length; i++) {
      if (gear[i].gear === "")
        return {
          status: false,
          error: "Gear is required.",
        };
      if (gear[i].gearHighLights === "")
        return {
          status: false,
          error: "Gear highlights is requird in gears.",
        };
    }

    // check social media
    // if (social_media.length > 0) {
    //   for (let i = 0; i < social_media.length; i++) {
    //     if (social_media[i].plat === "")
    //       return {
    //         status: false,
    //         error: "Social Media is requird",
    //       };
    //     if (social_media[i].link === "") {
    //       return {
    //         status: false,
    //         error: "Social Media is required.",
    //       };
    //     } else {
    //       const val = isValidURL(social_media[i].link);

    //       if (!val.status) {
    //         return val;
    //       }
    //     }
    //   }
    // }

    // check terms and services
    for (let i = 0; i < term.length; i++) {
      if (term[i] === "") {
        return { status: false, error: "Terms and Services is required." };
      }
    }

    // check agree tick
    if (!accept)
      return {
        status: false,
        error:
          "To proceed further you need to accept our terms and conditions.",
      };

    return {
      status: true,
      error: "",
    };
  };

  // functions to handle submissions
  const handleSubmit = async () => {
    // check for the username if it exists or not
    set_loading(true);

    if (checkFields().status) {
      const res = await checkForUserName(username);

      let c_s = State.getStatesOfCountry("IN").filter((val) => {
        if (val.isoCode === ustate) {
          return true;
        }
        return false;
      });
      // let city_name =
      // let state_name = State.getStatesOfCountry('IN')

      let t_state = c_s[0].name;

      if (res === "notAvailable") {
        set_check_username_availability(true);
        set_loading(false);
      } else {
        const temp_url = await uploadImage(avatar_object);

        // find the lowest value in categories
        let low = Number.MAX_VALUE;

        for (let i = 0; i < categories.length; i++) {
          if (categories[i].serviceStargingPrice < low) {
            low = categories[i].serviceStargingPrice;
          }
        }

        registerTalentApi({
          fname,
          username,
          wa_number,
          city,
          t_state,
          description,
          categories,
          genre,
          gear,
          social_media,
          work,
          term,
          avatar: temp_url,
          id: location.state.id,
          startingPrice: low,
        })
          .then((res) => {
            localStorage.setItem("token", location.state.token);

            currentUser(location.state.token)
              .then((res) => {
                dispatch({
                  type: "LOGGED_IN_USER",
                  payload: {
                    userId: res.data._id,
                    name: res.data.name,
                    email: res.data.email,
                    token: location.state.token,
                    isMusician: res.data.isMusician,
                    isProfileCompleted: res.data.isProfileCompleted,
                    qr: res.data.profileUrl,
                    avatar: res.data.avatar,
                    role: res.data.role,
                    genres: res.data.genres,
                    services: res.data.services,
                    gearHighLights: res.data.gearHighLights,
                    review: res.data.review,
                    terms: res.data.terms,
                    postedJobs: res.data.postedJobs,
                    startingPrice: res.data.startingPrice,
                    jobsCompleted: res.data.jobsCompleted,
                    totalEarn: res.data.totalEarn,
                    repeatedBuyer: res.data.repeatedBuyer,
                    socialMedia: res.data.socialMedia,
                    city: res.data.city,
                    description: res.data.description,
                    mobile: res.data.mobile,
                    userName: res.data.userName,
                  },
                });
                set_loading(false);
                sessionStorage.setItem("profileStatus", "success");
                sessionStorage.setItem("profileType", "client");
                toast({
                  title: "Success",
                  description: "Registered Successfully.",
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                  position: "top",
                });
                navigate("/dashboard");
              })
              .catch((error) => {
                set_loading(false);
              });
          })
          .catch((err) => {
            alert(err);
            set_loading(false);
          });

        // .catch((error) => {
        // 	alert("error occurred while uploading image");
        // });

        set_check_username_availability(false);

        // now update the data
      }
    } else {
      set_loading(false);
      toast({
        title: "Error",
        description: capitalizeFirstLetter(checkFields().error),
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      {!initial_load ? (
        <Box pt="8.5vh" overflowX={"hidden"}>
          {/* <NavBar/> */}
          <Box px={{ base: "7vw", lg: "13.54vw" }} pt="6.01vh" pb="100px">
            <Text
              display={"block"}
              fontSize={{ base: "1.5rem", lg: "2.29vw" }}
              fontFamily={"Gilroy-Bold"}
            >
              Talent Registration
            </Text>
            {/* personal info */}
            <TalentRegistrationPersonalInfo
              data={{
                fname,
                set_fname,
                username,
                set_username,
                wa_number,
                set_wa_number,
                city,
                set_city,
                ustate,
                set_state,
                description,
                set_description,
                check_username_availability,
                avatar,
                set_avatar,
                set_avatar_object,
              }}
            />

            {/* professional info */}
            <TalentRegistrationProfessionalInfo
              data={{
                categories,
                set_categories,
                gear,
                set_gear,
                genre,
                set_genre,
                social_media,
                set_social_media,
                work,
                set_work,
                term,
                set_term,
                accept,
                set_accept,
              }}
            />

            <Button
              mt="3.70vh"
              w="36.04vw"
              bg="rgba(246, 84, 14, 1)"
              color="white"
              h={"6.48vh"}
              borderRadius={{ base: "15px", lg: "1.04vw" }}
              fontFamily={"Gilroy-SemiBold"}
              fontSize={{ base: "1rem", md: "1.5rem", lg: ".833vw" }}
              _hover={{ background: "rgba(246, 84, 14, 1)" }}
              onClick={handleSubmit}
              isLoading={loading}
            >
              {editPage === true ? "Update Profile" : "Create Profile"}
            </Button>
          </Box>
          {/* <Footer /> */}
        </Box>
      ) : (
        <Box
          width={"100vw"}
          height={"100vh"}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <Spinner color="orange" size={"lg"} />
        </Box>
      )}
    </>
  );
};

export default TalentRegistration;
