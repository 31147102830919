import { Box, Text, Select, Input, useToast } from "@chakra-ui/react";
import { ReactComponent as DeleteIcon } from "../../../../Assets/Icons/Delete.svg";
import { ReactComponent as InfoIcon } from "../../../../Assets/Icons/info-circle.svg";
import { ReactComponent as CloseIcon } from "../../../../Assets/Icons/CloseIcon.svg";
import styles from "../../../../Styles/genre.module.css";
import mStyles from "../../../../Styles/termsandServices.module.css";
import { useState } from "react";

const TermsAndServices = ({
  showDelete,
  currentIndex,
  fullState,
  changeState,
}) => {
  const [modal, setModal] = useState(false);

  const toast = useToast();

  const handleDelete = () => {
    changeState((prev) => {
      prev.splice(currentIndex, 1);
      return [...prev];
    });
  };

  const handleChange = (e) => {
    changeState((old) => {
      old[currentIndex] = e.target.value;
      return [...old];
    });
  };

  return (
    <>
      <Box
        display={"flex"}
        gap={".833vw"}
        mt="1.111vh"
        position={"relative"}
        w="100%"
        paddingRight={{ base: showDelete ? "10px" : "0" }}
      >
        <Box width={"100%"}>
          <Text
            fontFamily={"Gilroy-SemiBold"}
            fontSize={{ base: ".9rem", md: "1.5rem", lg: ".833vw" }}
            display={"flex"}
            alignItems={"center"}
            gap={"3px"}
          >
            Terms of Service*{" "}
            <InfoIcon
              style={{ height: "16px", cursor: "pointer" }}
              onClick={() => setModal(true)}
            />
          </Text>
          <Input
            h="6.48vh"
            borderRadius={"15px"}
            width={"100%"}
            value={fullState[currentIndex]}
            onChange={handleChange}
          />
        </Box>

        {/* delete icon */}
        {showDelete ? (
          <DeleteIcon className={styles.icon} onClick={handleDelete} />
        ) : (
          <></>
        )}
      </Box>

      {modal && (
        <div className={mStyles.container}>
          <div className={mStyles.main}>
            <h3>
              Terms & Services Template{" "}
              <CloseIcon onClick={() => setModal(false)} />
            </h3>

            <ul>
              {TermsAndServicesTemplate.map((item, index) => (
                <li>
                  <p>{item}</p>{" "}
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(item);
                      setModal(false);
                      toast({
                        title: "Success",
                        description: "Copied successfully.",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                        position: "top",
                      });
                    }}
                  >
                    copy
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

const TermsAndServicesTemplate = [
  "Revision Limit: You get 3 revisions for tweaks and changes.",
  "Quick Turnaround: Expect your music in 1-2 weeks, or faster with a rush fee.",
  "Extra Services Talk: Anything extra? Let's chat and sort out the cost.",
  "Make It Yours: Customize your music for a unique touch, extra charges apply.",
  "Rights Explanation: You get to use the music, but exclusive rights cost extra.",
  "Collaboration Steps: Let's start by outlining the project together.",
  "Efficient Project Timeline: Your music will be ready in 1-2 weeks, and faster with a rush option.",
  "Credit and Ownership: I can showcase the work, you get the credit unless we agree otherwise.",
  "Feedback Matters: Timely and helpful feedback keeps things moving smoothly.",
  "Quality Pledge: I promise top-notch, pro-level music. If not happy, let's find a fix.",
];

export default TermsAndServices;
