import { Box, Text } from "@chakra-ui/react";

const IncomingMessage = ({ data }) => {
  try {
    return (
      <Box backgroundColor={"#F0F0F0"} p="1rem" borderRadius={"1rem"}>
        <Text fontFamily={"Gilroy-Medium"} fontSize="1.2rem">
          {JSON.parse(data.message)?.message}
        </Text>
        {JSON.parse(data.message)?.attachments.length !== 0 &&
          JSON.parse(data.message)?.attachments.map((val) => {
            return (
              <img
                src={val}
                height="200px"
                width="200px"
                style={{ borderRadius: "10px", marginTop: "1rem" }}
              />
            );
          })}
      </Box>
    );
  } catch (err) {
    return (
      <Box backgroundColor={"#F0F0F0"} p="1rem" borderRadius={"1rem"}>
        <Text fontFamily={"Gilroy-Medium"} fontSize="1.2rem">
          {data?.message}
        </Text>
      </Box>
    );
  }
};
export default IncomingMessage;
