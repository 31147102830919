import { Box, Text, Button } from "@chakra-ui/react";
import SignUpModal from "../../../Components/NavBar/SignUpModal";
import { useState } from "react";

const CreateAFreeAccount = () => {
  const [signupState, setSignupState] = useState(false);

  return (
    <Box
      w="100vw"
      px={{ base: "5vw", lg: "13.54vw" }}
      py={{ base: "10px", lg: "50px" }}
    >
      <SignUpModal state={signupState} changeState={setSignupState} />
      <Box
        bg="rgba(246, 84, 14, .05)"
        borderRadius={"32px"}
        overflow="hidden"
        display={"flex"}
        flexDir="column"
        justifyContent={"center"}
        alignItems="center"
        py="50px"
        gap="10px"
        h={{ base: "fit-content", "3xl": "45vh" }}
      >
        <Text
          className="hero-font-class1"
          fontSize={{ base: "2rem", lg: "3.37vw" }}
          w="90%"
          fontFamily="Gilroy-Bold"
          textAlign={"center"}
        >
          Create A Free Account <br /> and Get Started Today!
        </Text>
        <Button
          bg="rgba(246, 84, 14, 1)"
          color="#fff"
          h="6.66vh"
          w={{ base: "90%", lg: "13.59vw" }}
          borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
          mt="20px"
          fontFamily="Gilroy-SemiBold"
          fontSize={{ base: "1.1rem", lg: ".833vw" }}
          onClick={() => setSignupState(true)}
        >
          Become a member
        </Button>
      </Box>
    </Box>
  );
};

export default CreateAFreeAccount;
