import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Select,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";
import { useEffect, useState } from "react";
import GoToTop from "../../Utility/goToTop";
import { ColorRing } from "react-loader-spinner";
import { ReactComponent as CategoryIcon } from "../../../Assets/Icons/element-4.svg";
import { ReactComponent as MusicNoteIcon } from "../../../Assets/Icons/VectorGen.svg";
import { Helmet } from "react-helmet";

// icons
import { ReactComponent as SearchIcon } from "../../../Assets/Icons/search-normal.svg";
import { ReactComponent as RatingIcon } from "../../../Assets/Icons/star.svg";
import { ReactComponent as Star } from "../../../Assets/Icons/star.svg";

import TalentCard from "../../Components/talentCard/TalentCard";

// dummy data
import d_audio from "../../../Assets/Dummy/allthat.mp3";
import CategoryFilter from "./filters/CategoryFilter";
import BudgetFilter from "./filters/BudgetFilter";
import RatingFilter from "./filters/RatingFilter";
import GenreFilter from "./filters/GenreFilter";
import getAllUsers from "../../../Api/User/getAllUsers";
import { useCategoryContext } from "../../Context/CategoryContext";
import getAllTalents from "../../../Api/AllTalents/allTalents";
import { AccessAuthContext } from "../../Context/AuthContext";
import { useLocation } from "react-router-dom";
import CategoryData from "../../../Data/CategoryData";
import GenreData from "../../../Data/GenreData";

const Talents = () => {
  const [talents, setTalents] = useState([]);
  const [search, set_search] = useState("");
  const { categoryF, setCategoryF } = useCategoryContext();
  const [category, setCategory] = useState("");
  const [search_color, set_search_color] = useState("rgba(43, 43, 43, .3)");
  // const [temp_list, set_temp_list] = useState(d_data);
  const [start_price, set_star_price] = useState("");
  const [end_price, set_end_price] = useState("");
  const [star, set_star] = useState("");
  const [genre, set_genre] = useState("");
  const [show_clear, set_show_clear] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [allTalents, setAllTalents] = useState([]);
  const { loginState } = AccessAuthContext();
  //mobile view
  const [filterButton, setFilterButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const genres = [
    "National/ Indian",
    "International",
    "Classical",
    "Rock",
    "Pop",
    "Jazz",
  ];

  const getThis = async () => {
    getAllTalents()
      .then((res) => {
        setTalents(res.data.talents);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    //window.scrollTo(0, 0);

    getThis();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (!!location.state) {
      let temp = location.state;
      if (temp.type === "category") {
        setCategory(temp.value);
      }
      if (temp.type === "genre") {
        set_genre(temp.value);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (
      category === "" &&
      start_price === "" &&
      end_price === "" &&
      star === "" &&
      genre === ""
    ) {
      set_show_clear(false);
    } else {
      set_show_clear(true);
    }
  }, [category, start_price, end_price, star, genre]);

  const handleClear = () => {
    setCategory("");
    set_star_price("");
    set_end_price("");
    set_star("");
    set_genre("");
  };

  const filterIt = () => {
    let temp = talents.filter((val) => {
      return val.isProfileCompleted;
    });

    if (!!search) {
      temp = temp.filter((val) => {
        // first combine the categories
        let _categories = "";
        let _genre = "";

        if (val?.services) {
          for (let i = 0; i < val.services.length; i++) {
            if (val.services[i].service) {
              _categories =
                _categories + ":" + val.services[i].service.toLowerCase();
            }
          }
        }

        if (val?.genres) {
          for (let i = 0; i < val.genres.length; i++) {
            if (val.genres[i].genere) {
              _genre = _genre + ":" + val.genres[i].genere.toLowerCase();
            }
          }
        }

        return (
          val.name.toLowerCase().includes(search) ||
          _categories.includes(search) ||
          _genre.includes(search)
        );
      });
    }

    if (category !== "") {
      temp = temp.filter((val) => {
        if (val?.services) {
          for (let i = 0; i < val.services.length; i++) {
            if (val.services[i]?.subService) {
              if (val.services[i].subService.trim() === category.trim()) {
                return true;
              }
            }

            if (val.services[i]?.subCategory) {
              if (val.services[i].subCategory.trim() === category.trim()) {
                return true;
              }
            }

            if (val.services[i].service) {
              if (val.services[i].service.trim() === category.trim()) {
                return true;
              }
            }
            if (val.services[i].category) {
              if (val.services[i].category.trim() === category.trim()) {
                return true;
              }
            }
          }
          return false;
        } else {
          return false;
        }
      });
    }

    if (genre !== "") {
      temp = temp.filter((val) => {
        if (val?.genres) {
          for (let i = 0; i < val.genres.length; i++) {
            if (val.genres[i].subGenere) {
              if (val.genres[i].subGenere.trim() === genre.trim()) {
                return true;
              }
            }

            if (val.genres[i]?.subGenre) {
              if (val.genres[i].subGenre.trim() === genre.trim()) {
                return true;
              }
            }

            if (val.genres[i]?.genre) {
              if (val.genres[i].genre.trim() === genre.trim()) {
                return true;
              }
            }

            return false;
          }
          return false;
        } else {
          return false;
        }
      });
    }

    if (!!start_price && !!end_price) {
      temp = temp.filter((val) => {
        if (val?.startingPrice) {
          if (
            Number(start_price) <= Number(val?.startingPrice) &&
            Number(end_price) >= Number(val?.startingPrice)
          ) {
            return true;
          }
        }
        return false;
      });
    }

    if (star !== "") {
      temp = [];
    }

    return temp;
  };

  return (
    <Box pt="70px">
      <Helmet>
        <title>
          {category === ""
            ? "Udukku"
            : `Discover ${category
                .toLowerCase()
                .split("")
                .map((val, index) => {
                  return index === 0 ? val.toUpperCase() : val;
                })
                .join("")} in India.`}
        </title>
      </Helmet>
      <NavBar />
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Talent Filter</ModalHeader>
          <div style={{ width: "100vw", height: "100vh", margin: 0 }}>
            <Box
              display={{ base: "flex" }}
              flexDir={"column"}
              gap="20px"
              mt="2.31vh"
              alignItems={"center"}
            >
              <Box
                h="6.48vh"
                border="1.5px solid #F0F0F0"
                borderRadius="15px"
                w={{ base: "90%" }}
                px="1.5vw"
                display={"flex"}
                alignItems={"center"}
                fontSize={{ base: "1.5rem", lg: ".833vw" }}
                bg={category === "" ? "white" : "rgba(8, 32, 50, 1)"}
                color={category === "" ? "black" : "white"}
              >
                <CategoryIcon
                  className="genre-category-icons2"
                  style={{
                    fill: category === "" ? "#082032" : "white",
                    width: "1.5vw",
                    height: "1.5vw",
                  }}
                />
                <Select
                  border="none"
                  fontSize={{ base: "1.5rem", lg: ".833vw" }}
                  paddingX={0}
                  fontFamily={"Gilroy-SemiBold"}
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                  // w={
                  // 	category === ""
                  // 		? "120px"
                  // 		: category === "SONGWRITERS & COMPOSERS" ||
                  // 		  category === "MUSIC PRODUCER & ENGINEERS"
                  // 		? "270px"
                  // 		: "150px"
                  // }
                  _focus={{ minWidth: "150px" }}
                >
                  <option value="">Category</option>
                  {Object.keys(CategoryData).map((val, index) => {
                    return (
                      <>
                        <option key={index} value={val}>
                          {val}
                        </option>
                        {/* <optgroup key={index}> */}
                        {CategoryData[val].map((value, index) => {
                          return (
                            <option key={index} value={value}>
                              &nbsp;&nbsp;&nbsp;&nbsp;{value}
                            </option>
                          );
                        })}
                        {/* </optgroup> */}
                      </>
                    );
                  })}
                </Select>
              </Box>

              <Box
                h="6.48vh"
                border="1.5px solid #F0F0F0"
                borderRadius="15px"
                w={{ base: "90%" }}
                px="1.5vw"
                display={"flex"}
                alignItems={"center"}
                fontSize={{ base: "1.5rem", lg: ".833vw" }}
                bg={genre === "" ? "white" : "rgba(8, 32, 50, 1)"}
                color={genre === "" ? "black" : "white"}
              >
                <MusicNoteIcon
                  className="genre-category-icons2"
                  style={{
                    fill: genre === "" ? "#082032" : "#fff",
                    width: ".96vw",
                    height: "1.25vw",
                  }}
                />
                <Select
                  // textAlign={"center"}
                  border="none"
                  fontSize={{ base: "1.5rem", lg: ".833vw" }}
                  paddingX={0}
                  fontFamily={"Gilroy-SemiBold"}
                  value={genre}
                  onChange={(e) => {
                    set_genre(e.target.value);
                  }}
                  _focus={{ minWidth: "150px" }}
                >
                  <option value="">Genre</option>
                  {Object.keys(GenreData).map((val, index) => {
                    return (
                      // <optgroup label={val} key={index}>
                      <>
                        <option key={index} value={val}>
                          {val}
                        </option>
                        {GenreData[val].map((value, index) => {
                          return (
                            <option key={index} value={value}>
                              &nbsp;&nbsp;&nbsp;&nbsp;{value}
                            </option>
                          );
                        })}
                      </>
                      // </optgroup>
                    );
                  })}
                </Select>
              </Box>

              {/* <CategoryFilter Main={category} SetMainCat={setCategory} /> */}
              {/* <GenreFilter Main={genre} setMainGenre={set_genre} /> */}

              <BudgetFilter
                MainStart={start_price}
                MainEnd={end_price}
                setMainStart={set_star_price}
                setMainEnd={set_end_price}
              />

              <Box
                h="6.48vh"
                border="1.5px solid #F0F0F0"
                borderRadius="15px"
                w={{ base: "90%" }}
                px="1.5vw"
                display={"flex"}
                alignItems={"center"}
                fontSize={{ base: "1.5rem", lg: ".833vw" }}
                bg={star === "" ? "white" : "rgba(8, 32, 50, 1)"}
                color={star === "" ? "black" : "white"}
              >
                <RatingIcon
                  className="genre-category-icons2"
                  style={{
                    fill: star === "" ? "#082032" : "#fff",
                    width: ".96vw",
                    height: "1.25vw",
                  }}
                />
                <Select
                  border="none"
                  fontSize={{ base: "1.5rem", lg: ".833vw" }}
                  paddingX={0}
                  fontFamily={"Gilroy-SemiBold"}
                  value={star}
                  onChange={(e) => {
                    set_star(e.target.value);
                  }}
                  _focus={{ minWidth: "150px" }}
                >
                  <option value="">Rating</option>

                  <option value={1}>1 Start</option>
                  <option value={2}>2 Start</option>
                  <option value={3}>3 Start</option>
                  <option value={4}>4 Start</option>
                  <option value={5}>5 Start</option>
                </Select>
              </Box>

              {/* <RatingFilter Main={star} setMainStar={set_star} /> */}

              <Box flexGrow={1}></Box>

              {show_clear ? (
                <Text
                  color="rgba(246, 84, 14, 1)"
                  textDecor={"underline"}
                  fontFamily="Gilroy-SemiBold"
                  fontSize={"1.5rem"}
                  cursor={"pointer"}
                  onClick={handleClear}
                >
                  Clear Filter
                </Text>
              ) : (
                <></>
              )}
              <div style={{ flexGrow: 1 }}></div>
              <Button
                w="90%"
                h="50px"
                fontSize={"1.3rem"}
                margin={"auto"}
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
            </Box>
          </div>
        </ModalContent>
      </Modal>
      {loading ? (
        <>
          <Box
            w="100vw"
            h="50vh"
            display="flex"
            flexDir="row"
            alignItems="center"
            justifyContent="center"
          >
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#F6540E", "#F6540E", "#F6540E", "#F6540E", "#F6540E"]}
            />
          </Box>
        </>
      ) : (
        <Box
          px={{ base: "7vw", lg: "13.54vw" }}
          pt="7.40vh"
          minH="calc(100vh - 7.40vh)"
        >
          <Box
            display={"flex"}
            flexDir={{ base: "column", lg: "row" }}
            justifyContent={"space-between"}
            alignItems={{ base: "flex-start", lg: "center" }}
          >
            <Text
              fontSize={{ base: "2.4rem", lg: "2.29vw" }}
              fontFamily={"Gilroy-Bold"}
              w="100%"
            >
              Discover{" "}
              {category === ""
                ? "Pro Musicians"
                : category
                    .toLocaleLowerCase()
                    .split("")
                    .map((val, index) => {
                      return index === 0 ? val.toUpperCase() : val;
                    })
                    .join("")}{" "}
              in India
            </Text>
            <Box display={"flex"} w="100%" gap={{ base: ".8rem", lg: "" }}>
              <InputGroup
                w={{ base: "80%", lg: "100%" }}
                _focus={{
                  svg: { stroke: "rgba(246, 84, 14, 1) !important" },
                }}
              >
                <InputLeftElement
                  pointerEvents="none"
                  h="100%"
                  p="6px"
                  marginLeft={"9px"}
                  children={<SearchIcon style={{ stroke: search_color }} />}
                />
                <Input
                  borderRadius={"15px"}
                  h={{ base: "4rem", lg: "5rem" }}
                  type="text"
                  fontSize={{ base: "1.2rem", lg: ".93vw" }}
                  placeholder="Enter talent name, category or genre"
                  paddingLeft={"41px"}
                  _focus={{
                    border: "2px solid rgba(246, 84, 14, 1)",
                  }}
                  onFocus={() => {
                    set_search_color("rgba(246, 84, 14, 1)");
                  }}
                  onBlur={() => {
                    set_search_color("rgba(43, 43, 43, .3)");
                  }}
                  value={search}
                  onChange={(e) => {
                    set_search(e.target.value);
                  }}
                />
              </InputGroup>
              <Box
                display={{ base: "flex", lg: "none" }}
                borderRadius={"1.2rem"}
                border="1.5px solid #F0F0F0"
                px="2rem"
                alignItems={"center"}
                justifyContent="center"
                onClick={() => {
                  onOpen();
                }}
              >
                <Text fontSize={"1.2rem"}>Filters</Text>
              </Box>
            </Box>
          </Box>

          {/* filters */}
          <Box
            display={{ base: "none", lg: "flex" }}
            gap=".62vw"
            mt="2.31vh"
            alignItems={"center"}
          >
            <Box
              h="6.48vh"
              border="1.5px solid #F0F0F0"
              borderRadius="1.25vw"
              w="fit-content"
              px="1.5vw"
              display={"flex"}
              alignItems={"center"}
              fontSize={{ base: "1.5rem", lg: ".833vw" }}
              bg={category === "" ? "white" : "rgba(8, 32, 50, 1)"}
              color={category === "" ? "black" : "white"}
            >
              <CategoryIcon
                className="genre-category-icons2"
                style={{
                  fill: category === "" ? "#082032" : "white",
                  width: "1.5vw",
                  height: "1.5vw",
                }}
              />
              <Select
                border="none"
                fontSize={{ base: "1.5rem", lg: ".833vw" }}
                paddingX={0}
                fontFamily={"Gilroy-SemiBold"}
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
                w={
                  category === ""
                    ? "120px"
                    : category === "SONGWRITERS & COMPOSERS" ||
                      category === "MUSIC PRODUCER & ENGINEERS"
                    ? "270px"
                    : "150px"
                }
                _focus={{ minWidth: "150px" }}
              >
                <option value="">Category</option>
                {Object.keys(CategoryData).map((val, index) => {
                  return (
                    <>
                      <option key={index} value={val}>
                        {val}
                      </option>
                      {/* <optgroup key={index}> */}
                      {CategoryData[val].map((value, index) => {
                        return (
                          <option key={index} value={value}>
                            &nbsp;&nbsp;&nbsp;&nbsp;{value}
                          </option>
                        );
                      })}
                      {/* </optgroup> */}
                    </>
                  );
                })}
              </Select>
            </Box>

            <Box
              h="6.48vh"
              border="1.5px solid #F0F0F0"
              borderRadius="1.25vw"
              w="fit-content"
              px="1.5vw"
              display={"flex"}
              alignItems={"center"}
              fontSize={{ base: "1.5rem", lg: ".833vw" }}
              bg={genre === "" ? "white" : "rgba(8, 32, 50, 1)"}
              color={genre === "" ? "black" : "white"}
            >
              <MusicNoteIcon
                className="genre-category-icons2"
                style={{
                  fill: genre === "" ? "#082032" : "white",
                  width: ".96vw",
                  height: "1.25vw",
                }}
              />
              <Select
                border="none"
                fontSize={{ base: "1.5rem", lg: ".833vw" }}
                paddingX={0}
                fontFamily={"Gilroy-SemiBold"}
                value={genre}
                onChange={(e) => {
                  set_genre(e.target.value);
                }}
                w={
                  genre === ""
                    ? "120px"
                    : category === "SONGWRITERS & COMPOSERS" ||
                      category === "MUSIC PRODUCER & ENGINEERS"
                    ? "270px"
                    : "150px"
                }
                _focus={{ minWidth: "150px" }}
              >
                <option value="">Genre</option>
                {Object.keys(GenreData).map((val, index) => {
                  return (
                    // <optgroup label={val} key={index}>
                    <>
                      <option key={index} value={val}>
                        {val.toUpperCase()}
                      </option>
                      {GenreData[val].map((value, index) => {
                        return (
                          <option key={index} value={value}>
                            &nbsp;&nbsp;&nbsp;&nbsp;{value}
                          </option>
                        );
                      })}
                    </>
                    // </optgroup>
                  );
                })}
              </Select>
            </Box>

            <BudgetFilter
              MainStart={start_price}
              MainEnd={end_price}
              setMainStart={set_star_price}
              setMainEnd={set_end_price}
            />

            <Box
              h="6.48vh"
              border="1.5px solid #F0F0F0"
              borderRadius="1.25vw"
              w="fit-content"
              px="1.5vw"
              display={"flex"}
              alignItems={"center"}
              fontSize={{ base: "1.5rem", lg: ".833vw" }}
              bg={star === "" ? "white" : "rgba(8, 32, 50, 1)"}
              color={star === "" ? "black" : "white"}
            >
              <RatingIcon
                className="genre-category-icons2"
                style={{
                  fill: star === "" ? "#082032" : "#fff",
                  width: ".96vw",
                  height: "1.25vw",
                }}
              />
              <Select
                border="none"
                fontSize={{ base: "1.5rem", lg: ".833vw" }}
                paddingX={0}
                fontFamily={"Gilroy-SemiBold"}
                value={star}
                onChange={(e) => {
                  set_star(e.target.value);
                }}
                w={
                  star === ""
                    ? "120px"
                    : category === "SONGWRITERS & COMPOSERS" ||
                      category === "MUSIC PRODUCER & ENGINEERS"
                    ? "270px"
                    : "150px"
                }
                _focus={{ minWidth: "150px" }}
              >
                <option value="">Rating</option>

                <option value={1}>⭐️</option>
                <option value={2}>⭐️ ⭐️</option>
                <option value={3}>⭐️ ⭐️ ⭐️</option>
                <option value={4}>⭐️ ⭐️ ⭐️ ⭐️</option>
                <option value={5}>⭐️ ⭐️ ⭐️ ⭐️ ⭐️</option>
              </Select>
            </Box>

            <Box flexGrow={1}></Box>

            {show_clear ? (
              <Text
                color="rgba(246, 84, 14, 1)"
                textDecor={"underline"}
                fontFamily="Gilroy-SemiBold"
                fontSize={".833vw"}
                cursor={"pointer"}
                onClick={handleClear}
              >
                Clear Filter
              </Text>
            ) : (
              <></>
            )}
          </Box>
          {/* cards Section */}
          <Box
            mt="3.70vh"
            display={
              talents ? (filterIt().length === 0 ? "flex" : "grid") : "grid"
            }
            gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
            columnGap={".833vw"}
            rowGap={"1.48vh"}
            mb="5.55vh"
          >
            {talents && filterIt().length === 0 ? (
              <Box
                width={"100%"}
                height={"300px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontSize={"20px"}
                textAlign={"center"}
              >
                No Talents Found
              </Box>
            ) : (
              filterIt().map((talent) => {
                return <TalentCard key={talent._id} data={talent} />;
              })
            )}
          </Box>
        </Box>
      )}
      <Footer />
      <GoToTop />
    </Box>
  );
};

export default Talents;
