import { Box, Image, Text } from "@chakra-ui/react";
import clipBoard from "../../../../Assets/Images/icos/clipboard-text.png";
import message from "../../../../Assets/Images/icos/messages.png";
import card from "../../../../Assets/Images/icos/card-tick.png";

const data = [
  {
    title: "Post a Job",
    description:
      "List your job by entering a title, detailed description, and some other information about the work you need completed",
    image: clipBoard,
  },
  {
    title: "Chat & Choose",
    description:
      "Browse through a listing of providers, chat with him, and choose the one you would like to work with.",
    image: message,
  },
  {
    title: "Pay Securely",
    description:
      "Pay securely with Udukku and release funds to the musician only when the job is done and you are 100% satisfied with the result",
    image: card,
  },
];

const LookingForAMusician = () => {
  return (
    <Box
      bg="rgb(253,247,243)"
      pt="7.40vh"
      pb="7.40vh"
      display={"flex"}
      flexDir="column"
      alignItems={"center"}
      justifyContent="start"
    >
      <Text
        fontSize={{ base: "2rem !important", lg: "2.29vw !important" }}
        textAlign="center"
        fontFamily={"Gilroy-Bold"}
        className="hero-font-class1"
      >
        Looking For A Musician?
      </Text>
      <Box
        display={"grid"}
        gridTemplateColumns={{ base: "repeat(1,1fr)", lg: "repeat(3,1fr)" }}
        justifyItems={"center"}
        px={{ base: "2rem", lg: "13.54vw" }}
        pt={{ base: "30px", lg: "50px" }}
        pb="10px"
        gridGap={{ base: "2rem", lg: "0rem" }}
      >
        {data.map((val, index) => {
          return (
            <Box
              display={"flex"}
              flexDir="column"
              alignItems={"center"}
              w={{ base: "70%", lg: "80%" }}
              gap="10px"
            >
              <Box
                w={{ base: "5.5rem", lg: "6vw" }}
                h={{ base: "5.5rem", lg: "6vw" }}
                border="3px solid #F6540E"
                borderRadius={"full"}
                display="flex"
                justifyContent={"center"}
                alignItems="center"
              >
                <Image
                  src={val.image}
                  h={{ base: "2.5rem", lg: "3vw" }}
                  w={{ base: "2.5rem", lg: "3vw" }}
                />
              </Box>
              <Text
                fontSize={{
                  base: "1.5rem !important",
                  lg: "1.45vw !important",
                }}
                fontFamily={"Gilroy-Bold"}
                className="hero-font-class1"
              >
                {val.title}
              </Text>
              <Text
                textAlign={"center"}
                fontFamily={"Gilroy-Medium"}
                fontSize={{
                  base: "1.2rem !important",
                  lg: "1.3rem !important",
                }}
                className="hero-font-class2"
              >
                {val.description}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default LookingForAMusician;
