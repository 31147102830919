import checkUsernameApi from "../../Api/Registration/checkUsernameApi";

const checkForUserName = async (username) => {
	try {
		const res = await checkUsernameApi(username);
		if (res.data.message === "Username is available") {
			return "available";
		}
	} catch (error) {
		return "notAvailable";
	}
};

export default checkForUserName;
