export const GearData = [
	"Laptop",
	"Headphone",
	"Sound Card",
	"Guitar",
	"Speakers",
	"Drum Kit",
	"Microphone",
	"Guitar",
	"Amplifier",
	"DJ Console",
	"Boombox",
	"Synthesiser/ Keyboard/ Midi",
	"Piano",
	'Harmonium',
	"Tabla",
	"String Players",
	"Wind Instrument",
	"Laptop",
	"Mixer",
	"Acoustic panels",
	"Diffusers",
	"Virtual Instruments",
	"Digital Convertors",
	"Master Clocks",
	"Analog Hardware",
	"Direct boxes",
	"Microphone Preamp",
	"Headphone Amp",
	"Power conditioner",
	"Studio Rack mount",
	"Additional Monitors",
	"Other",
]

export default GearData;