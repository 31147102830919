import {
  Box,
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Footer from "../../Components/Footer/Footer";
import NavBar from "../../Components/NavBar/NavBar";
import React, { useRef } from "react";

// icon
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { ReactComponent as BackIcon } from "../../../Assets/Icons/arrow-left.svg";
import { ReactComponent as ManIcon } from "../../../Assets/Icons/man.svg";
import GenreFields from "./GenreFields";
import { useState } from "react";
import PeopleInvolved from "./PeopleInvolved";
import SocialMedia from "./SocialMedia";
import { useNavigate } from "react-router-dom";
import uploadImage from "../../../Api/Lyrics/uploadImage";
import createLyrics from "../../../Api/Lyrics/createLyrics";
import { Icon, CheckCircleIcon } from "@chakra-ui/react";
import { AiFillCheckCircle } from "react-icons/ai";
import { capitalizeFirstLetter } from "../../Utility/utils";
import TextEditor from "../../Components/TextEditor";

const CreateNewLyrics = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [genre, set_genre] = useState([{ genre: "", subgenre: "" }]);
  const [peopleInvolved, set_peopleInvolved] = useState([
    { peopleInvolved: "", role: "" },
  ]);
  const [social_media, set_social_media] = useState([{ plat: "", link: "" }]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputFile = useRef();
  const form = useRef(null);
  const image_input_ref = useRef();
  const [image_blob_link, set_image_blob_link] = useState();
  const toast = useToast();
  const [lyrics, setLyrics] = useState("");

  const handleClick = async (e) => {
    inputFile.current.click();
  };

  function CheckCircleIcon() {
    return <Icon as={CheckCircleIcon} />;
  }

  const handleImageSubmit = (e) => {
    uploadImage(e.target.files[0]).then((res) => {
      // let url = res.imagesKey[0];
      setImage(res);
    });
  };

  const validate = (e) => {
    let message = "";

    if (!image) {
      message = "image is required";
    }

    if (!e.target.form.songName.value && !message) {
      message = "song name is required";
    }

    if (!e.target.form.artistName.value && !message) {
      message = "Artist name is required";
    }

    if (!lyrics && !message) {
      message = "Lyrics is required";
    }

    if (!e.target.form.aboutArtist.value && !message) {
      message = "About artist field is required";
    }

    if (!e.target.form.youtubeVideoLink.value && !message) {
      message = "Youtube link is required";
    }

    for (let i = 0; i < social_media.length; i++) {
      if (!social_media[i].plat && !message) {
        message = "Social media field is required";
      }

      if (!social_media[i].link && !message) {
        message = "Social media link is required";
      }
    }

    if (message) {
      toast({
        title: "Error",
        status: "error",
        description: capitalizeFirstLetter(message),
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return false;
    } else {
      return true;
    }
  };

  const createLyricsForm = async (e) => {
    // let url = await uploadImage(image);
    //"https://res.cloudinary.com/dqimeuotl/image/upload/v1660906420/qkry0soeymijlpuhhw7o.webp";
    // const image = url;

    if (validate(e)) {
      const songName = e.target.form.songName.value;
      const artistName = e.target.form.artistName.value;
      const lyrics = lyrics;

      const aboutArtist = e.target.form.aboutArtist.value;
      const youtubeVideoLink = e.target.form.youtubeVideoLink.value;

      var status = await createLyrics(
        image,
        songName,
        artistName,
        genre,
        //subgenre,
        lyrics,
        aboutArtist,
        peopleInvolved,
        social_media,
        youtubeVideoLink
      ).then(() => {
        onOpen(true);
      });
    }
  };

  return (
    <Box pt="8.5vh">
      <NavBar />
      <Box
        px={{ base: "7vw", lg: "13.54vw" }}
        pt="6.01vh"
        minH="calc(100vh - 7.40vh)"
      >
        <Box width={{ md: "36.04vw", sm: "100vw" }}>
          {/* heading with back button */}
          <Box display={"flex"} gap={".833vw"} alignItems="center">
            <BackIcon
              style={{
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
                stroke: "#f6540e",
              }}
              onClick={() => {
                navigate("/lyrics");
              }}
            />
            <Text
              className="hero-font-class1"
              fontFamily={"Gilroy-Bold"}
              fontSize="2.29vw"
            >
              Add New Song Lyrics
            </Text>
          </Box>

          {/* cover photo */}
          <form ref={form}>
            <Box
              mt="4.44vh"
              display={"flex"}
              alignItems="center"
              gap={"1.25vw"}
            >
              <Box
                className="single-Lyrics-page-avatar"
                width={"7.29vw"}
                height="7.29vw"
                bg="rgba(8, 32, 50, .1)"
                borderRadius={"100%"}
                overflow="hidden"
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
              >
                {image && image?.length !== 0 ? (
                  <Image src={image} width={"7.29vw"} height="7.29vw" />
                ) : (
                  <MusicNoteIcon
                    className="genre-category-icons"
                    style={{
                      width: "3.125vw",
                      height: "3.125vw",
                      color: "rgba(246, 84, 14, 1)",
                    }}
                  />
                )}
              </Box>
              <input
                type="file"
                id="file"
                onChange={handleImageSubmit}
                ref={inputFile}
                style={{ display: "none" }}
              />
              <Button
                className="single-Lyrics-page-button hero-font-class2"
                bg="rgba(246, 84, 14, 1)"
                onClick={handleClick}
                color={"white"}
                w="11.97vw"
                s
                h="6.48vh"
                borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
                fontFamily="Gilroy-SemiBold"
                fontSize={{ base: "1.2rem", lg: ".833vw" }}
                _hover={{ background: "rgba(246, 84, 14, 1)" }}
              >
                Upload Cover Photo
              </Button>
            </Box>

            {/* song name */}
            <Box mt="2.22vh" fontFamily={"Gilroy-SemiBold"}>
              <Text
                className="single-Lyrics-page-heading1"
                fontSize=".833vw"
                ml={"1%"}
              >
                Song Name*
              </Text>
              <Input
                className="single-Lyrics-page-heading1"
                name="songName"
                fontSize="1.2rem"
                mt=".37vh"
                w="100%"
                h="6.48vh"
                borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
                placeholder="Central Cee"
                type={"text"}
              />
            </Box>

            {/* artist name */}
            <Box mt="2.22vh" fontFamily={"Gilroy-SemiBold"}>
              <Text
                className="single-Lyrics-page-heading1"
                fontSize=".833vw"
                ml={"1%"}
              >
                Artist Name*
              </Text>
              <Input
                className="single-Lyrics-page-heading1"
                name="artistName"
                fontSize="1.2rem"
                mt=".37vh"
                w="100%"
                h="6.48vh"
                borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
                placeholder="Doja"
                type={"text"}
              />
            </Box>

            {/* Genre field */}

            <Box mt="2.22vh">
              {genre.map((_data, index) => {
                return (
                  <GenreFields
                    key={index}
                    currentIndex={index}
                    fullState={genre}
                    changeState={set_genre}
                    showDelete={genre.length > 1 ? true : false}
                  />
                );
              })}
            </Box>
            <Text
              className="single-Lyrics-page-heading1"
              fontFamily={"Gilroy-SemiBold"}
              color="rgba(246, 84, 14, 1)"
              fontSize={".833vw"}
              ml={"1%"}
              onClick={() => {
                set_genre((prev) => {
                  prev.push({
                    genre: "",
                    subgenre: "",
                  });
                  return [...prev];
                });
              }}
              cursor="pointer"
            >
              + Add another Category
            </Text>

            {/* Lyrics */}
            <Box mt="2.22vh" fontFamily={"Gilroy-SemiBold"}>
              <Text
                className="single-Lyrics-page-heading1"
                fontSize=".833vw"
                ml={"1%"}
              >
                Lyric*
              </Text>
              {/* <Textarea
                className="single-Lyrics-page-heading1"
                fontSize="1.2rem"
                py="2.22vh"
                mt=".37vh"
                w="100%"
                h="19.4vh"
                name="lyrics"
                borderRadius={"1.04vw"}
                placeholder="[Chorus]
How can I be homophobic? My bitch is gay
Hit man in the top, try see a man topless, even the stick is gay
Huggin' my bruddas and say that I love them, but I don't swing that way
The mandem celebrate Eid, the trap still runnin' on Christmas day"
                type={"text"}
              /> */}
              <TextEditor
                content={lyrics}
                handleChange={(val) => {
                  console.log(val);
                  setLyrics(val);
                }}
              />
            </Box>

            {/* About Artist */}
            <Box mt="2.22vh" fontFamily={"Gilroy-SemiBold"}>
              <Text
                className="single-Lyrics-page-heading1"
                fontSize=".833vw"
                ml={"1%"}
              >
                About Artist*
              </Text>
              <Textarea
                className="single-Lyrics-page-heading1"
                fontSize="1.2rem"
                py="2.22vh"
                mt=".37vh"
                w="100%"
                h="19.4vh"
                name="aboutArtist"
                borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
                placeholder="“Doja” is Central Cee’s first single since the release of his February 2022 mixtape 23 as well as a string of music videos from the mixtape. The track’s beat samples Eve and Gwen Stefani’s classic May 2001 track, “Let Me Blow Ya Mind.”"
                type={"text"}
              />
            </Box>

            {/* people involved */}
            <Box mt="2.22vh">
              {peopleInvolved.map((_data, index) => {
                return (
                  <PeopleInvolved
                    key={index}
                    currentIndex={index}
                    fullState={peopleInvolved}
                    changeState={set_peopleInvolved}
                    showDelete={peopleInvolved.length > 1 ? true : false}
                  />
                );
              })}
              <Text
                className="single-Lyrics-page-heading1"
                fontFamily={"Gilroy-SemiBold"}
                color="rgba(246, 84, 14, 1)"
                fontSize={".833vw"}
                ml={"1%"}
                onClick={() => {
                  set_peopleInvolved((prev) => {
                    prev.push({
                      peopleInvolved: "",
                      role: "",
                    });
                    return [...prev];
                  });
                }}
                cursor="pointer"
              >
                + Add People Involved
              </Text>
            </Box>

            {/* social media */}
            <Box mt="2.22vh">
              {social_media.map((_data, index) => {
                return (
                  <SocialMedia
                    key={index}
                    currentIndex={index}
                    fullState={social_media}
                    changeState={set_social_media}
                    showDelete={social_media.length > 1 ? true : false}
                  />
                );
              })}
              <Text
                className="single-Lyrics-page-heading1"
                fontFamily={"Gilroy-SemiBold"}
                color="rgba(246, 84, 14, 1)"
                fontSize={".833vw"}
                ml={"1%"}
                onClick={() => {
                  set_social_media((prev) => {
                    prev.push({
                      plat: "",
                      link: "",
                    });
                    return [...prev];
                  });
                }}
                cursor="pointer"
              >
                + Add another Social Media
              </Text>
            </Box>

            {/* Youtube link */}
            <Box mt="2.22vh" fontFamily={"Gilroy-SemiBold"}>
              <Text
                className="single-Lyrics-page-heading1"
                fontSize=".833vw"
                ml={"1%"}
              >
                Youtube Video Link*
              </Text>
              <Input
                className="single-Lyrics-page-heading1"
                name="youtubeVideoLink"
                fontSize="1.2rem"
                mt=".37vh"
                w="100%"
                h="6.48vh"
                borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
                placeholder="https://www.youtube.com/watch?v=YcpIr_PLvUY&list=PL8B3jvnmLJ8YLuyP0-9a8zrM..."
                type={"text"}
              />
            </Box>

            {/* Submit button */}

            <Button
              className="single-Lyrics-page-heading1"
              mt="3.70vh"
              mb="7.40vh"
              h="6.48vh"
              w="100%"
              bg="rgba(246, 84, 14, 1)"
              borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
              onClick={createLyricsForm}
              color="white"
              _hover={{ background: "rgba(246, 84, 14, 1)" }}
            >
              Create New Lyric
            </Button>
            <Modal
              isOpen={isOpen}
              onClose={() => {
                onClose();
                navigate("/lyrics");
              }}
              size="lg"
            >
              <ModalOverlay />
              <ModalContent
                mt="auto"
                mb="auto"
                height="30vh"
                borderRadius={"20px"}
              >
                <ModalCloseButton />
                <ModalBody
                  textAlign="center"
                  display={"flex"}
                  flexDir={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Icon as={AiFillCheckCircle} color="green" fontSize="3rem" />
                  <Text fontSize="2rem">Lyrics Created Successfully.</Text>
                  <Button
                    bg="rgba(246, 84, 14, 1)"
                    color="#fff"
                    mt="30px"
                    mr="auto"
                    ml="auto"
                    px="10"
                    onClick={() => {
                      onClose();
                      navigate("/lyrics");
                    }}
                  >
                    OK
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
          </form>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default CreateNewLyrics;
