import { Box, Text, Button, Image } from "@chakra-ui/react";
import "../";
import "./hero.css";
import { ReactComponent as People } from "../../../../Assets/Icons/people.svg";
import { ReactComponent as Man } from "../../../../Assets/Icons/frame.svg";
import Slide from "./Slide";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../Common/CustomButton";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Box
      // h={{ base: "100vh", md: "100vh", lg: "73.70vh", xl: "73.70vh" }}
      w="100vw"
      overflow={"hidden"}
      bg="#082032"
      display={"flex"}
      alignItems="center"
      px={{ base: "2rem", lg: "12.54vw" }}
      gap={{ base: "10px", lg: "100px" }}
      pt={{
        base: "3rem", // 0-48em
        md: "5rem", // 48em-80em,
        xl: "4rem", // 80em+
      }}
      mt="5rem"
      pb="7.40vh"
      flexDir={{
        base: "column", // 0-48em
        md: "column",
        lg: "row", // 48em-80em,
        xl: "row", // 80em+
      }}
    >
      <Box
        className="hero-image-radius"
        display={"flex"}
        alignItems="center"
        justifyContent={{ base: "center", md: "center" }}
        flexDir={{
          base: "column", // 0-48em
          md: "column",
          lg: "row", // 48em-80em,
          xl: "row", // 80em+
        }}
        gap={{ base: "1rem" }}
        px={{ base: "0" }}
      >
        {/* text section on left */}
        <Box
          className="hero-box"
          display={"flex"}
          flexDir="column"
          gap={{ base: "2rem", xl: "30px" }}
          justifyContent={{ base: "center" }}
        >
          <Text
            as="h1"
            fontSize={{ base: "6rem", lg: "3.75vw", sm: "3rem" }}
            color="#fff"
            lineHeight={{ base: "1.2em" }}
            fontFamily={"Gilroy-Bold"}
            w="100%"
            className="hero-font-class1"
          >
            Join India's leading Music Marketplace
          </Text>
          <Text
            as="p"
            color="#fff"
            fontSize={{ base: "1.3rem !important", lg: ".8333vw" }}
            fontFamily={"Gilroy-Medium"}
            className="hero-font-class2"
            textAlign={{ base: "center", lg: "start" }}
          >
            Get discovered for your music skills and explore India’s unique
            musical talents at Udukku
          </Text>
          <Box
            className="hero-buttons"
            display={"flex"}
            gap={{ base: "3rem", lg: "50px" }}
            mt={{ base: "1rem", lg: "40px" }}
            width={{ base: "100%", lg: "70%" }}
          >
            <CustomButton type={"filled"} onClick={() => navigate("/talents")}>
              <People /> Discover Talents
            </CustomButton>
            <CustomButton
              type={"outlined"}
              onClick={() => {
                navigate("/jobs");
              }}
            >
              <Man />
              Be Discovered
            </CustomButton>
          </Box>
        </Box>
        <Slide />
      </Box>
    </Box>
  );
};

export default Hero;
