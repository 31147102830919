import { Box, Button, Image, Text } from "@chakra-ui/react";
import Footer from "../../Components/Footer/Footer";
import NavBar from "../../Components/NavBar/NavBar";
import SignInModal from "../../Components/NavBar/SignInModal";
import SignInModalMobile from "../../Components/NavBar/SignInModalMobile";

// Icons
import { ReactComponent as Category } from "../../../Assets/Icons/category.svg";
import { ReactComponent as Star } from "../../../Assets/Icons/star.svg";
import { ReactComponent as MusicIcon } from "../../../Assets/Icons/VectorGen.svg";
import { ReactComponent as Sms } from "../../../Assets/Icons/orange-sms.svg";
import { ReactComponent as DollarCircle } from "../../../Assets/Icons/dollar-circle.svg";
import { ReactComponent as ClockIcon } from "../../../Assets/Icons/clock.svg";
import { ReactComponent as AttachCircle } from "../../../Assets/Icons/attach-circle.svg";
import { ReactComponent as LinkIcon } from "../../../Assets/Icons/link-2.svg";
import { ReactComponent as ClipboardTick } from "../../../Assets/Icons/clipboard-tick.svg";
import getJobById from "../../../Api/Jobs/getJobById";

// dummy
import d_audio from "../../../Assets/Dummy/allthat.mp3";
import ReviewCard from "../../Components/ReviewCard/ReviewCard";
import { useNavigate, useParams } from "react-router-dom";
import getJobs from "../../../Api/Jobs/getJobsApi";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const d_data = [
  {
    profile_link: "https://source.unsplash.com/random?face?girl",
    name: "Ishita Parakh",
    state: "Rajasthan",
    stars: 3,
    worked_on: "",
    description: "",
    time: "1 week ago",
  },
  {
    profile_link: "https://source.unsplash.com/random?face?women",
    name: "Sunidhi Chauhan",
    state: "Gujarat",
    stars: 4,
    worked_on: "",
    description: "",
    time: "2 week ago",
  },
  {
    profile_link: "https://source.unsplash.com/random?face",
    name: "Shreya Ghoshal",
    state: "Delhi",
    stars: 2,
    worked_on: "",
    description: "",
    time: "1 month ago",
  },
];

const JobDetailPage = () => {
  const { id } = useParams();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [mobileSignin, setMobileSignin] = useState(false);
  const [signin, setSignin] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));

  const getData = async () => {
    try {
      const res = await getJobById(id);

      setJobs(res.data);
      setLoading(false);
    } catch (e) {}
  };

  const formatDate = (date) => {
    date = new Date(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const handleContactMe = () => {
    if (!user) {
      setSignin(true);
    } else {
      navigate(`/messages`);
    }
  };

  return (
    <>
      <SignInModalMobile state={mobileSignin} changeState={setMobileSignin} />
      <SignInModal state={signin} changeState={setSignin} />
      <Box mt="7.40vh">
        <NavBar />
        {loading && (
          <Box
            px={{ base: "7vw", lg: "13.54vw" }}
            pt="7.40vh"
            minH="calc(100vh - 7.40vh)"
          ></Box>
        )}
        {!loading && (
          <Box
            px={{ base: "7vw", lg: "13.54vw" }}
            pt="7.40vh"
            minH="calc(100vh - 7.40vh)"
          >
            <Box
              display="grid"
              gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
              gap="1.85vw"
            >
              <Box w={{ base: "100%", lg: "36.04vw" }} h="fit-content">
                <Box w="100%" mt="2.96vh">
                  {/* heading */}
                  <Box
                    display={"flex"}
                    alignItems="center"
                    w="100%"
                    gap=".833vw"
                  >
                    <Text
                      fontFamily="Gilroy-Bold"
                      fontSize={{
                        base: "2rem !important",
                        lg: "2.29vw !important",
                      }}
                      // w='30.36vw'
                    >
                      {jobs?.jobTitle}
                    </Text>

                    {jobs?.liveShow === true ? (
                      <Text
                        w="3.85vw"
                        h="4.07vh"
                        bg="#f60e0e"
                        color="white"
                        fontFamily={"Gilroy-SemiBold"}
                        fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                        display={"flex"}
                        justifyContent="center"
                        alignItems={"center"}
                        borderRadius=".833vw"
                      >
                        Live
                      </Text>
                    ) : (
                      ""
                    )}
                  </Box>

                  {/* Time */}
                  <Text
                    color={"rgba(43, 43, 43, .5)"}
                    fontFamily={"Gilroy-SemiBold"}
                    fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                  >
                    {formatDate(jobs?.updatedAt)}
                  </Text>

                  {/* Tags - 1 */}
                  <Box
                    display={"flex"}
                    mt="2.39vh"
                    gap={{ base: "10px", lg: "1.77vw" }}
                  >
                    <Box display={"flex"} alignItems="center" gap=".52vw">
                      <DollarCircle
                        style={{
                          width: "20px",
                          height: "20px",
                          opacity: 0.5,
                        }}
                      />
                      <Text
                        fontFamily={"Gilroy-Medium"}
                        fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                      >
                        Fixed Price
                      </Text>
                    </Box>
                    <Box display={"flex"} alignItems="center" gap=".52vw">
                      <ClockIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          opacity: 0.5,
                        }}
                      />
                      <Text
                        fontFamily={"Gilroy-Medium"}
                        fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                      >
                        {jobs?.deadLine}
                      </Text>
                    </Box>

                    {jobs?.referenceLinks ? (
                      <Box display={"flex"} alignItems="center" gap=".52vw">
                        <AttachCircle
                          style={{
                            width: "1.04vw",
                            height: "1.04vw",
                            opacity: 0.5,
                          }}
                        />
                        <Text
                          fontFamily={"Gilroy-Medium"}
                          fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                        >
                          {jobs?.referenceLinks}
                        </Text>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>

                  {/* Tags - 2 */}
                  <Box
                    display={"flex"}
                    flexWrap="wrap"
                    mt="2.60vh"
                    gap={{ base: "5px", lg: ".416vw" }}
                  >
                    <Box
                      h="4.07vh"
                      display={"inline-flex"}
                      alignItems="center"
                      gap={{ base: "5px", lg: ".49vw" }}
                      bg="rgba(192, 226, 24, .1)"
                      py={{ base: "5px", lg: "1.20vh" }}
                      pl={{ base: "5px", lg: ".75vw" }}
                      pr={{ base: "5px", lg: ".62vw" }}
                      borderRadius={"10px"}
                    >
                      <Category
                        style={{
                          fill: "black",
                          height: "15px",
                          width: "15px",
                        }}
                      />
                      <Text
                        fontFamily={"Gilroy-SemiBold"}
                        fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                      >
                        {jobs?.category.service}
                      </Text>
                    </Box>
                    <Box
                      h="4.07vh"
                      display={"inline-flex"}
                      alignItems="center"
                      gap={{ base: "5px", lg: ".49vw" }}
                      bg="rgba(192, 226, 24, .1)"
                      py={{ base: "5px", lg: "1.20vh" }}
                      pl={{ base: "5px", lg: ".75vw" }}
                      pr={{ base: "5px", lg: ".62vw" }}
                      borderRadius={"10px"}
                    >
                      <Category
                        style={{
                          fill: "black",
                          height: "15px",
                          width: "15px",
                        }}
                      />
                      <Text
                        fontFamily={"Gilroy-SemiBold"}
                        fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                      >
                        {jobs?.category.subservice}
                      </Text>
                    </Box>

                    {jobs?.genres.map((gen) => {
                      return (
                        <Box
                          h="4.07vh"
                          display={"inline-flex"}
                          alignItems="center"
                          gap={{ base: "5px", lg: ".49vw" }}
                          bg="rgba(247, 215, 22, .1)"
                          py={{ base: "5px", lg: "1.20vh" }}
                          pl={{ base: "5px", lg: ".75vw" }}
                          pr={{ base: "5px", lg: ".62vw" }}
                          borderRadius={"10px"}
                        >
                          <MusicIcon
                            style={{
                              fill: "black",
                              height: "15px",
                              width: "15px",
                            }}
                          />
                          <Text
                            fontFamily={"Gilroy-SemiBold"}
                            fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                          >
                            {gen.genere}
                          </Text>
                        </Box>
                      );
                    })}
                  </Box>

                  {/* audio player */}
                  {jobs?.workSample ? (
                    <Box mt="3.70vh">
                      <audio
                        style={{
                          width: "100%",
                          color: "orange",
                          fill: "orange",
                        }}
                        src={d_audio}
                        controls
                      />
                    </Box>
                  ) : (
                    ""
                  )}

                  {/* link section */}
                  <Box
                    display={"flex"}
                    mt="2.40vh"
                    alignItems={"center"}
                    gap=".41vw"
                  >
                    {jobs?.referenceLinks ? (
                      <Box display={"flex"} alignItems="center" gap=".52vw">
                        <AttachCircle
                          style={{
                            width: "1.04vw",
                            height: "1.04vw",
                            opacity: 0.5,
                          }}
                        />
                        <Text
                          fontFamily={"Gilroy-Medium"}
                          fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                        >
                          {jobs?.referenceLinks}
                        </Text>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>

                  {/* Description */}
                  <Box
                    mt="2.96vh"
                    fontFamily={"Gilroy-Medium"}
                    fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                  >
                    {jobs?.description}
                  </Box>

                  {/* Price */}
                  <Text
                    fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                    fontFamily="Gilroy-Bold"
                    mt="1.48vh"
                  >
                    ₹{jobs?.budget[0]}
                  </Text>
                </Box>
              </Box>

              <Box w={{ base: "100%", lg: "35vw" }} h="10px">
                {/* starting price section */}
                <Box
                  w="100%"
                  mt="2.22vh"
                  px={{ base: "10px", lg: ".833vw" }}
                  pt={{ base: "15px", lg: "2.59vh" }}
                  pb={{ base: "10px", lg: "1.48vh" }}
                  border={"2px solid rgba(240, 240, 240, 1)"}
                  borderRadius={{ base: "20px", lg: "1.66vw" }}
                >
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Box
                      display={"flex"}
                      alignItems="center"
                      gap={{ base: "10px", lg: ".72vw" }}
                    >
                      <Box
                        w={{ base: "60px", lg: "5.20vw" }}
                        h={{ base: "60px", lg: "5.20vw" }}
                        bgImage={jobs?.jobPostedBy?.avatar}
                        bgSize="cover"
                        borderRadius={"full"}
                        bgPos="center"
                      ></Box>
                      <Box h="fit-content">
                        <Text
                          fontFamily={"Gilroy-Bold"}
                          fontSize={{ base: "1.3rem", lg: "1.04vw" }}
                        >
                          {jobs?.jobPostedBy?.name
                            ? jobs?.jobPostedBy?.name
                            : ""}
                        </Text>
                        <Text
                          fontFamily={"Gilroy-Medium"}
                          color="rgba(43, 43, 43, .5)"
                          fontSize={{ base: "1rem", lg: "1.04vw" }}
                          mt=".18vh"
                        >
                          {jobs?.jobPostedBy?.city}
                        </Text>
                        <Box display={"flex"} mt=".52vh">
                          {[...Array(4)].map((data, index) => {
                            return (
                              <Star
                                key={index}
                                style={{
                                  fill: "rgba(247, 215, 22, 1)",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            );
                          })}
                          {[...Array(1)].map((data, index) => {
                            return (
                              <Star
                                key={index}
                                style={{
                                  fill: "#D9D9D9",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            );
                          })}{" "}
                          <Text
                            ml=".39vw"
                            fontFamily={"Gilroy-SemiBold"}
                            fontSize={{ base: "1rem", lg: "1.04vw" }}
                          >
                            4
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      fontFamily={"Gilroy-SemiBold"}
                      fontSize={{ base: "1rem", lg: "1.04vw" }}
                      textAlign={"end"}
                    >
                      {jobs?.jobPostedBy?.postedJobs ? (
                        <>
                          <Text>{jobs?.jobPostedBy?.postedJobs}</Text>
                          {/* <Text mt=".74vh">₹{jobs?.jobPostedBy?.} total spent</Text> */}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                  <Button
                    mt="3.70vh"
                    h="6.48vh"
                    w="100%"
                    borderRadius={{ base: "15px", lg: "1.04vw" }}
                    bg="#F6540E"
                    _hover={{ background: "#F6540E" }}
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    onClick={() =>
                      navigate(
                        `/respond-to-job/${jobs?._id}-${jobs.jobPostedBy._id}`
                      )
                    }
                  >
                    <ClipboardTick
                      style={{
                        width: "15px",
                        height: "15px",
                        fill: "#F6540E !important",
                      }}
                    />
                    <Text
                      ml=".36vw"
                      color="white"
                      fontFamily={"Gilroy-SemiBold"}
                      fontSize={{ base: "1rem", lg: ".8vw" }}
                    >
                      Respond to the job
                    </Text>
                  </Button>
                  <Button
                    display={{ base: "none", lg: "inline-flex" }}
                    mt="1.48vh"
                    h="6.48vh"
                    w="100%"
                    borderRadius={{ base: "15px", lg: "1.04vw" }}
                    bg="white"
                    border="2px solid #F6540E  "
                    onClick={() => {
                      if (!user) {
                        setSignin(true);
                      } else {
                        navigate(`/messages`);
                      }
                    }}
                  >
                    <Sms
                      style={{
                        width: "15px",
                        height: "15px",
                        fill: "#F6540E !important",
                      }}
                    />
                    <Text
                      ml=".36vw"
                      color="#F6540E"
                      fontFamily={"Gilroy-SemiBold"}
                      fontSize={{ base: "1rem", lg: ".8vw" }}
                    >
                      Contact me
                    </Text>
                  </Button>
                  <Button
                    display={{ base: "inline-flex", lg: "none" }}
                    mt="1.48vh"
                    h="6.48vh"
                    w="100%"
                    borderRadius={{ base: "15px", lg: "1.04vw" }}
                    bg="white"
                    border="2px solid #F6540E  "
                    onClick={() => {
                      if (!user) {
                        setMobileSignin(true);
                      } else {
                        navigate(`/messages`);
                      }
                    }}
                  >
                    <Sms
                      style={{
                        width: "15px",
                        height: "15px",
                        fill: "#F6540E !important",
                      }}
                    />
                    <Text
                      ml=".36vw"
                      color="#F6540E"
                      fontFamily={"Gilroy-SemiBold"}
                      fontSize={{ base: "1rem", lg: ".8vw" }}
                    >
                      Contact me
                    </Text>
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* Review Section */}
            {jobs?.review ? (
              <Box mt="5.55vh">
                <Text fontFamily="Gilroy-Bold" fontSize={"1.45vw"}>
                  Reviews (3)
                </Text>
                <Box
                  mt="1.01vh"
                  mb="7.40vh"
                  display={"flex"}
                  flexDir="column"
                  gap="1.48vh"
                >
                  {d_data.map((data, index) => {
                    return <ReviewCard key={index} data={data} />;
                  })}
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        )}
        <Footer />
      </Box>
    </>
  );
};

export default JobDetailPage;
