import { Box, Input, Select, Text } from "@chakra-ui/react";
import { ReactComponent as DeleteIcon } from "../../../Assets/Icons/Delete.svg";

const genres = [
  {
    title: "National/ Indian",
    sub: [
      `Hindustani Classical`,
      `Carnatic Classical`,
      `Bollywood Classical`,
      `Bollywood Semi-Classical`,
      `Rag-based`,
      "Filmi Qawaali",
      "Rabindra Sangeet",
      "Riyaz",
      "Zamzama",
      "Tappa",
      "Tarana",
      "Bollywood Pop",
      "Rap",
      "Dance or DJ music",
      "Indie Rock",
      "Indie Pop",
      "Indian Rock",
      "Indian Pop",
      "Indian Hip Hop",
      "Raga Rock",
      "Indian Jazz",
      "Sufi Rock",
      "Punjabi",
      "Bhojpuri",
      "Chutney",
      "Hindu Devotional",
      "Muslim Devotional",
      "Bhajan",
      "Kirtan",
      "Ghazal",
      "Qawaali",
      "Sufi",
      "Thumri",
      "Bandish",
      "Khyal",
      "Patriotic",
      "Goa Trance",
      "Jazz and Blues",
      "Western Classical Music",
      "Indonesian and Malay music",
      "Thai music",
    ],
  },
  {
    title: "Regional",
    sub: [
      "Tamang Selo (West Bengal, Sikkim, Nepal)",
      "Bhangra",
      "Giddha",
      "Borgeet",
      "Dandiya",
      "Haryanvi",
      "Himachali",
      "Lavani",
      "Manipuri",
      "Marfa (Hyderabad)",
      "Mizo (Burma)",
      "Odissi",
      "Rabindra Sangeet",
      "Rajasthani",
      "Sufi folk rock",
      "Sufi rock",
      "Uttarakhandi",
      "Bhangra",
      "Lung",
    ],
  },
  {
    title: "International",
    sub: [
      "Reggaeton",
      "Rock and Roll",
      "Techno",
      "Techno pop",
      "EDM",
      "Electronic",
      "Heavy Rock",
      "Heavy Metal",
      "House",
      "Hip Hop",
      "Rock",
      "Reggaeton ",
      "Rock and Roll",
      "Techno",
      "Techno pop",
      "EDM ",
      "Electronic",
      "Heavy Rock",
      "Heavy Metal",
      "House ",
      "Hip Hop",
      "Rock ",
      "Pop",
      "Blues",
      "Chill",
      "Dubstep",
      "Gospel",
      "Indie Pop ",
      "Jazz",
      "Pop-Rock",
      "R&B - Soul",
      "Singer-songwriter",
      "Electric Pop",
      "Country ",
      "Folk/ Acoustic",
      "Funk ",
      "Heavy ",
      "Latin",
      "Reggae",
      "Soft Pop",
      "Trance ",
      "Trap",
      "Afrobeat ",
      "Americana ",
      "Choral",
      "Classical",
      "Tropical/Dancehall",
      "World ",
    ],
  },
];

const GenreFields = ({ showDelete, currentIndex, fullState, changeState }) => {
  const handleDelete = () => {
    changeState((prev) => {
      prev.splice(currentIndex, 1);
      return [...prev];
    });
  };

  const updating_genre = (e) => {
    changeState((prev) => {
      prev[currentIndex].genre = e.target.value;
      return [...prev];
    });
  };
  const updating_sub_genre = (e) => {
    changeState((prev) => {
      prev[currentIndex].subgenre = e.target.value;
      return [...prev];
    });
  };

  return (
    <Box display={"flex"} gap=".833vw" mt="1.111vh" position={"relative"}>
      <Box flexGrow={1}>
        <Text
          className="single-Lyrics-page-heading1"
          fontFamily={"Gilroy-SemiBold"}
          fontSize=".833vw"
          display={currentIndex > 0 ? "none" : ""}
          ml={"1%"}
        >
          Genre*
        </Text>
        <Select
          className="single-Lyrics-page-heading1"
          h="6.48vh"
          type={"text"}
          fontSize="1.2rem"
          value={fullState[currentIndex].genre}
          onChange={updating_genre}
          borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
          defaultValue={"Select"}
        >
          {genres.map((val, index) => {
            return (
              <option key={index} value={val.title}>
                {val.title}
              </option>
            );
          })}
        </Select>
      </Box>
      <Box flexGrow={1}>
        <Text
          className="single-Lyrics-page-heading1"
          fontFamily={"Gilroy-SemiBold"}
          fontSize=".833vw"
          display={currentIndex > 0 ? "none" : ""}
          ml={"1%"}
        >
          Subgenre*
        </Text>
        <Select
          className="single-Lyrics-page-heading1"
          h="6.48vh"
          type={"text"}
          fontSize="1.2rem"
          borderRadius={{ base: "1.2rem", lg: "1.04vw" }}
          //   value={fullState[currentIndex].subgenre}
          onChange={updating_sub_genre}
          defaultValue={"Select"}
        >
          {fullState[currentIndex].genre &&
            genres
              .filter((val) => {
                if (val.title === fullState[currentIndex].genre) {
                  return true;
                }
                return false;
              })[0]
              .sub.map((value, index) => {
                return (
                  <option value={value} key={index}>
                    {value}
                  </option>
                );
              })}
        </Select>
      </Box>
      {/* delete icon */}
      {showDelete ? (
        <DeleteIcon
          style={{
            position: "absolute",
            right: "-30px",
            top: "50%",
            transform: "translateY(-20%)",
            width: "1.25vw",
            height: "1.25vw",
            cursor: "pointer",
          }}
          onClick={handleDelete}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default GenreFields;
